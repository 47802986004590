import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Form from 'components/form'
import Grid from 'components/grid'
import Input from 'components/input'
import Select from 'components/select'

import { BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import { IFilter } from 'services/hooks/use_filter'

import {
  BookingMerchandiseContainerContent,
  BookingMerchandiseProduct,
} from 'views/booking/slices/types'

import useStaticLocales from 'views/locales/hooks/use_static_locales'

import {
  StyledAddMerchandiseItemWrapper,
  StyledHazardousGoodsItemInput,
  StyledHazardousGoodsWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'

import Button from 'components/button'
import Textarea from 'components/textarea'

import {
  getTestIdForMerchandiseContainer,
  getTestIdForMerchandiseContainerProduct,
  getTestIdForMerchandiseProductCurrencyCode,
  getTestIdForMerchandiseProductMaximalTemperature,
} from 'tests/e2e/test_ids'

interface MerchandiseContainerProps {
  merchandiseFilter: IFilter<'custom'>
}

const MerchandiseContainer: FC<MerchandiseContainerProps> = ({
  merchandiseFilter,
}: MerchandiseContainerProps) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

  return (
    <Form.Group>
      {merchandiseFilter.value.container.map(
        (_container: BookingMerchandiseContainerContent, index: number) => (
          <Form.Insert subtitle={`${t('bookings.merchandise.containerRequest')} #${index + 1}`}>
            {index !== 0 && (
              <StyledRemoveMerchandiseItemWrapper>
                <Button
                  disabled={merchandiseFilter.isDisabled}
                  variant='icon'
                  icon='trash'
                  onClick={() => {
                    merchandiseFilter.onChange((state: any) => {
                      state.container.splice(index, 1)
                    })
                  }}
                />
              </StyledRemoveMerchandiseItemWrapper>
            )}
            <Grid columns={3}>
              <Grid.Row testId={getTestIdForMerchandiseContainer(index)}>
                <Grid.Column>
                  <Select
                    label={t('bookings.merchandise.type')}
                    placeholder={t('bookings.merchandise.containerType')}
                    options={Object.entries(s('containerTypes'))
                      .map(([key, value]) => ({
                        value: key,
                        label: value,
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    name={`containerType-${index}`}
                    value={merchandiseFilter.value.container[index]?.containerType}
                    onChange={({ value }) => {
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.container[index]) state.container[index] = {}
                        state.container[index].containerType = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    isDisabled={merchandiseFilter.isDisabled}
                    inputId={`${merchandiseFilter.name}-containerType-${index}`}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    type='number'
                    label={t('bookings.merchandise.quantity')}
                    placeholder={t('bookings.merchandise.quantity')}
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY}
                    name={`containerType-${index}`}
                    error={merchandiseFilter.error?.container[index]?.quantity}
                    value={merchandiseFilter.value.container[index]?.quantity}
                    onChange={({ target: { value } }) => {
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.container[index]) state.container[index] = {}
                        state.container[index].quantity = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    disabled={merchandiseFilter.isDisabled}
                    id={`${merchandiseFilter.name}-quantity-${index}`}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {merchandiseFilter.value.container[index]?.products.map(
                  (_product: BookingMerchandiseProduct, productIndex: number) => (
                    <Form.Insert
                      subtitle={`${t('bookings.merchandise.merchandiseDetails')} #${
                        productIndex + 1
                      }`}
                    >
                      {productIndex !== 0 && (
                        <StyledRemoveMerchandiseItemWrapper>
                          <Button
                            disabled={merchandiseFilter.isDisabled}
                            variant='icon'
                            icon='trash'
                            onClick={() => {
                              merchandiseFilter.onChange((state: any) => {
                                state.container[index].products.splice(productIndex, 1)
                              })
                            }}
                          />
                        </StyledRemoveMerchandiseItemWrapper>
                      )}
                      <Grid columns={3}>
                        <Grid.Row
                          testId={getTestIdForMerchandiseContainerProduct(index, productIndex)}
                        >
                          <Grid.Column>
                            <Input
                              type='number'
                              label={t('bookings.merchandise.weight')}
                              placeholder='kg'
                              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                              step='0.01'
                              name={`container-${index}-merchandiseWeight-${productIndex}`}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .weight?.value
                              }
                              error={
                                merchandiseFilter.error?.container[index]?.products[productIndex]
                                  ?.weight
                              }
                              onChange={({ target: { value } }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  if (!state.container[index].products[productIndex].weight)
                                    state.container[index].products[productIndex].weight = {}

                                  state.container[index].products[productIndex].weight.value = value
                                })
                              }
                              disabled={merchandiseFilter.isDisabled}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Input
                              type='number'
                              label={t('bookings.merchandise.volume')}
                              placeholder='cbm'
                              step='0.01'
                              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME}
                              name={`container-${index}-merchandiseVolume-${productIndex}`}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .volume?.value
                              }
                              error={
                                merchandiseFilter.error?.container[index]?.products[productIndex]
                                  ?.volume
                              }
                              onChange={({ target: { value } }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  if (!state.container[index].products[productIndex].volume)
                                    state.container[index].products[productIndex].volume = {}

                                  state.container[index].products[productIndex].volume.value = value
                                })
                              }
                              disabled={merchandiseFilter.isDisabled}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Input
                              label={t('bookings.merchandise.numberOfPackages')}
                              placeholder={t('bookings.merchandise.numberOfPackages')}
                              type='number'
                              step='1'
                              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES}
                              name={`container-${index}-merchandisePackageNumber-${productIndex}`}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .packageNumber
                              }
                              error={
                                merchandiseFilter.error?.container[index]?.products[productIndex]
                                  ?.packageNumber
                              }
                              onChange={({ target: { value } }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  state.container[index].products[productIndex].packageNumber =
                                    value
                                })
                              }
                              disabled={merchandiseFilter.isDisabled}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Textarea
                              label={t('bookings.merchandise.productDescription')}
                              placeholder={t('bookings.merchandise.yourDescription')}
                              name={`container-${index}-merchandiseDescription-${productIndex}`}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .productDescription
                              }
                              rows={4}
                              compact
                              onChange={({ target: { value } }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  state.container[index].products[productIndex].productDescription =
                                    value
                                })
                              }
                              disabled={merchandiseFilter.isDisabled}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Input
                              type='number'
                              step='0.01'
                              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}
                              label={t('bookings.merchandise.commercialValue')}
                              placeholder={t('bookings.merchandise.amount')}
                              name={`container-${index}-merchandiseAmount-${productIndex}`}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .commercialValue?.amount
                              }
                              error={
                                merchandiseFilter.error?.container[index]?.products[productIndex]
                                  ?.commercialValueAmount
                              }
                              onChange={({ target: { value } }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  if (
                                    !state.container[index].products[productIndex].commercialValue
                                  )
                                    state.container[index].products[productIndex].commercialValue =
                                      {}
                                  state.container[index].products[
                                    productIndex
                                  ].commercialValue.amount = value
                                })
                              }
                              disabled={merchandiseFilter.isDisabled}
                            />
                            <Select
                              placeholder={t('bookings.merchandise.currency')}
                              options={fromStaticToSelectOptions('currencies')}
                              testId={getTestIdForMerchandiseProductCurrencyCode(
                                productIndex,
                                index
                              )}
                              name={`container-${index}-merchandiseCurrencyCode-${productIndex}`}
                              isDisabled={merchandiseFilter.isDisabled}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .commercialValue?.currencyCode
                              }
                              error={
                                merchandiseFilter.error?.container[index]?.products[productIndex]
                                  ?.commercialValueCurrencyCode
                              }
                              onChange={({ value }: { value: any }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  if (
                                    !state.container[index].products[productIndex].commercialValue
                                  )
                                    state.container[index].products[productIndex].commercialValue =
                                      {}
                                  state.container[index].products[
                                    productIndex
                                  ].commercialValue.currencyCode = value
                                })
                              }
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Input
                              label={t('bookings.merchandise.controlledTemperatures.title')}
                              placeholder={t('bookings.merchandise.controlledTemperatures.minimal')}
                              type='number'
                              step='0.01'
                              disabled={merchandiseFilter.isDisabled}
                              name={`container-${index}-merchandiseTemperatureMinimal-${productIndex}`}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .controlledTemperatures?.min
                              }
                              onChange={({ target: { value } }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  if (
                                    !state.container[index].products[productIndex]
                                      .controlledTemperatures
                                  )
                                    state.container[index].products[
                                      productIndex
                                    ].controlledTemperatures = {}
                                  state.container[index].products[
                                    productIndex
                                  ].controlledTemperatures.min = value
                                })
                              }
                            />
                            <Input
                              placeholder={t('bookings.merchandise.controlledTemperatures.maximal')}
                              type='number'
                              step='0.01'
                              testId={getTestIdForMerchandiseProductMaximalTemperature(
                                productIndex,
                                index
                              )}
                              disabled={merchandiseFilter.isDisabled}
                              name={`container-${index}-merchandiseTemperatureMaximal-${productIndex}`}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .controlledTemperatures?.max
                              }
                              onChange={({ target: { value } }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  if (
                                    !state.container[index].products[productIndex]
                                      .controlledTemperatures
                                  )
                                    state.container[index].products[
                                      productIndex
                                    ].controlledTemperatures = {}
                                  state.container[index].products[
                                    productIndex
                                  ].controlledTemperatures.max = value
                                })
                              }
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Select
                              label={t(
                                'bookings.merchandise.product.hazardousGoods.hazardousClass'
                              )}
                              placeholder={t('actions.select')}
                              options={fromStaticToSelectOptions('hazardousGoods')}
                              name={`container-${index}-merchandiseHazardousClass-${productIndex}`}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .hazardousGoods?.hazardousClass
                              }
                              isDisabled={merchandiseFilter.isDisabled}
                              onChange={({ value }: { value: any }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  if (!state.container[index].products[productIndex].hazardousGoods)
                                    state.container[index].products[productIndex].hazardousGoods =
                                      {}
                                  state.container[index].products[
                                    productIndex
                                  ].hazardousGoods.hazardousClass = value
                                })
                              }
                              isClearable
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Select
                              label={t('bookings.merchandise.product.hazardousGoods.packingGroup')}
                              placeholder={t('actions.select')}
                              isDisabled={merchandiseFilter.isDisabled}
                              options={fromStaticToSelectOptions('packingGroups')}
                              name={`container-${index}-merchandiseHazardousPackingGroup-${productIndex}`}
                              value={
                                merchandiseFilter.value.container[index].products[productIndex]
                                  .hazardousGoods?.packingGroup
                              }
                              onChange={({ value }: { value: any }) =>
                                merchandiseFilter.onChange((state: any) => {
                                  if (!state.container[index].products[productIndex].hazardousGoods)
                                    state.container[index].products[productIndex].hazardousGoods =
                                      {}
                                  state.container[index].products[
                                    productIndex
                                  ].hazardousGoods.packingGroup = value
                                })
                              }
                              isClearable
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <StyledHazardousGoodsWrapper>
                              <StyledHazardousGoodsItemInput
                                label={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                                placeholder={t(
                                  'bookings.merchandise.product.hazardousGoods.unNumber'
                                )}
                                disabled={merchandiseFilter.isDisabled}
                                name={`container-${index}-merchandiseHazardousUnNumber-${productIndex}`}
                                value={
                                  merchandiseFilter.value.container[index].products[productIndex]
                                    .hazardousGoods?.unNumber
                                }
                                onChange={({ target: { value } }) =>
                                  merchandiseFilter.onChange((state: any) => {
                                    if (
                                      !state.container[index].products[productIndex].hazardousGoods
                                    )
                                      state.container[index].products[productIndex].hazardousGoods =
                                        {}
                                    state.container[index].products[
                                      productIndex
                                    ].hazardousGoods.unNumber = value
                                  })
                                }
                              />
                              <StyledHazardousGoodsItemInput
                                label={t('bookings.merchandise.product.hazardousGoods.weight')}
                                placeholder='kg'
                                type='number'
                                step='0.01'
                                disabled={merchandiseFilter.isDisabled}
                                min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                                name={`container-${index}-merchandiseHazardousWeight-${productIndex}`}
                                value={
                                  merchandiseFilter.value.container[index].products[productIndex]
                                    .hazardousGoods?.weight?.value
                                }
                                error={
                                  merchandiseFilter.error?.container[index]?.products[productIndex]
                                    ?.hazardousWeight
                                }
                                onChange={({ target: { value } }) =>
                                  merchandiseFilter.onChange((state: any) => {
                                    if (
                                      !state.container[index].products[productIndex].hazardousGoods
                                    )
                                      state.container[index].products[productIndex].hazardousGoods =
                                        {}

                                    if (
                                      !state.container[index].products[productIndex].hazardousGoods
                                        .weight
                                    )
                                      state.container[index].products[
                                        productIndex
                                      ].hazardousGoods.weight = {}

                                    state.container[index].products[
                                      productIndex
                                    ].hazardousGoods.weight.value = value
                                  })
                                }
                              />
                            </StyledHazardousGoodsWrapper>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form.Insert>
                  )
                )}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column size={3}>
                  <StyledAddMerchandiseItemWrapper>
                    <Button
                      disabled={merchandiseFilter.isDisabled}
                      onClick={() =>
                        merchandiseFilter.onChange((state: any) => {
                          state.container[index].products[state.container[index].products.length] =
                            {}
                        })
                      }
                      icon='plus_outline'
                      variant='dashed'
                      text={`${t('actions.addNew', { context: 'plural' })} ${t(
                        'bookings.merchandise.merchandiseDetails'
                      ).toLowerCase()}`}
                    />
                  </StyledAddMerchandiseItemWrapper>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Insert>
        )
      )}
      <StyledAddMerchandiseItemWrapper>
        <Button
          disabled={merchandiseFilter.isDisabled}
          onClick={() =>
            merchandiseFilter.onChange((state: any) => {
              state.container[state.container.length] = { products: [{}] }
            })
          }
          icon='plus_outline'
          variant='dashed'
          text={`${t('actions.addNew', { context: 'female' })} ${t(
            'bookings.merchandise.containerRequest'
          ).toLowerCase()}`}
        />
      </StyledAddMerchandiseItemWrapper>
    </Form.Group>
  )
}

export default MerchandiseContainer
