import { Booking } from 'views/booking/slices/types'
import { Organization } from 'views/iam/types'
import { isPresent } from 'services/helpers/values'

// eslint-disable-next-line import/prefer-default-export
export const formatDefaultValues = (
  booking: Partial<Booking> | undefined,
  organization: Organization
) => {
  const result: Partial<Booking> = isPresent(booking) ? { ...booking } : {}
  if (isPresent(organization.forwarderId) && isPresent(organization.forwarderName)) {
    result.forwarder = {
      id: organization.forwarderId,
      name: organization.forwarderName,
    }
  }
  return result
}
