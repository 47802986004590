import { Booking, Transshipment, VesselSelectValue } from 'views/booking/slices/types'
import { compactArray, isNull, isPresent } from 'services/helpers/values'
import { LOCATION_TYPE_PORT } from 'constants/shipments'
import { TrustedRoute, Step } from 'views/trusted_routes/types/trusted_route'
import {
  AddressTranslationData,
  CarrierTranslationData,
  TranslateToBookingResponse,
  VesselTranslationData,
} from 'views/trusted_routes/slice'

const convertToBookingData = (
  trustedRoute: TrustedRoute,
  translationData: TranslateToBookingResponse
): { booking: Partial<Booking>; untranslatedAddresses: string[] } => {
  const untranslatedAddresses: string[] = []
  const ptd = formatDate(trustedRoute.legs[0].departure.date)
  const pta = formatDate(trustedRoute.legs.slice(-1)[0].arrival.date)
  const bookingData: Partial<Booking> = {
    transportPlan: {
      withPreCarriage: false,
      withOnCarriage: false,
      withPod: true,
      withPol: true,
    },
    // For (pol/shipper/revised)(Ptd/Pta)
    // we ensure that the value is always filled in (regardless of the client and which version of the booking it is on)
    polPtd: ptd,
    podPta: pta,
    shipperPtd: ptd,
    shipperPta: pta,
    revisedPtd: ptd,
    revisedPta: pta,
    voyageNumbers: compactArray(trustedRoute.legs.map((leg) => leg.voyage)),
  }
  // Routing
  // Warning
  // the order of the elements in untranslatedAddresses must be maintained so that it is in the same order as the
  // booking creation form. This will no longer be necessary once the form has been switched to react-hook-form.
  const translatedPol = getFromLocode(translationData, trustedRoute.legs[0].departure.port.locode)
  if (isPresent(translatedPol)) {
    bookingData.pol = { ...translatedPol, type: LOCATION_TYPE_PORT }
  } else {
    untranslatedAddresses.push(formatUntranslatedAddress(trustedRoute.legs[0].departure))
  }

  const transshipments = convertTransshipmentData(
    translationData,
    trustedRoute,
    untranslatedAddresses
  )
  if (transshipments.length > 0) {
    bookingData.transshipments = transshipments
  }

  const translatedPod = getFromLocode(
    translationData,
    trustedRoute.legs.slice(-1)[0].arrival.port.locode
  )
  if (isPresent(translatedPod)) {
    bookingData.pod = { ...translatedPod, type: LOCATION_TYPE_PORT }
  } else {
    untranslatedAddresses.push(formatUntranslatedAddress(trustedRoute.legs.slice(-1)[0].arrival))
  }

  // Transport details
  const vessels = convertVesselData(translationData, trustedRoute)
  if (vessels.length > 0) {
    bookingData.vessels = vessels
  }

  const translatedCarrier = getFromScac(translationData, trustedRoute.scac)
  if (isPresent(translatedCarrier)) {
    bookingData.carrier = translatedCarrier
  } else {
    bookingData.carrier = { name: trustedRoute.scac, id: -1 }
  }

  // Miscellaneous
  if (isPresent(trustedRoute.cutOffDate)) {
    bookingData.vesselCutOffDate = formatDate(trustedRoute.cutOffDate)
  }

  return { booking: bookingData, untranslatedAddresses }
}

const convertTransshipmentData = (
  translationData: TranslateToBookingResponse,
  trustedRoute: TrustedRoute,
  untranslatedAddress: string[]
): Transshipment[] => {
  const transshipments: Transshipment[] = []
  trustedRoute.legs.slice(1).forEach((leg, i) => {
    const translatedAddress = getFromLocode(translationData, leg.departure.port.locode)
    if (isPresent(translatedAddress)) {
      transshipments.push({
        address: { ...translatedAddress, type: LOCATION_TYPE_PORT },
        ptd: formatDate(leg.departure.date),
        pta: formatDate(trustedRoute.legs[i].arrival.date),
      })
    } else {
      untranslatedAddress.push(formatUntranslatedAddress(leg.departure))
      // This is a workaround used to insert partial data into booking transshipments, which the interface does not allow.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      transshipments.push({
        ptd: formatDate(leg.departure.date),
        pta: formatDate(trustedRoute.legs[i].arrival.date),
      })
    }
  })
  return transshipments
}

const convertVesselData = (
  translationData: TranslateToBookingResponse,
  trustedRoute: TrustedRoute
): VesselSelectValue[] => {
  const vessels: VesselSelectValue[] = []
  trustedRoute.legs.forEach((leg) => {
    if (isNull(leg.vesselImo)) {
      return
    }
    const vessel = getFromVesselImo(translationData, leg.vesselImo)
    if (isPresent(vessel)) {
      vessels.push({ name: vessel.name, id: vessel.vesselImo })
    } else {
      vessels.push({ name: leg.vesselImo.toString() })
    }
  })
  return vessels
}

/**
 * Ensures that the given date string is formatted to include a time component set to midnight UTC, allowing date
 * to be displayed without including a time offset in components working with datetime.
 *
 * @param {string} date - The date string to be formatted.
 * @returns {string} The formatted date string with the time set to midnight in UTC.
 */
export const formatDate = (date: string): string => `${date}T00:00:00Z`

const formatUntranslatedAddress = (step: Step): string =>
  `${step.port.name}, ${step.port.country} (${step.port.locode})`

const getFromLocode = (
  translationData: TranslateToBookingResponse,
  locode: string
): AddressTranslationData | undefined =>
  translationData.addresses.find((address) => address.locode === locode)

const getFromScac = (
  translationData: TranslateToBookingResponse,
  scac: string
): CarrierTranslationData | undefined =>
  translationData.carriers.find((carrier) => carrier.scac === scac)

const getFromVesselImo = (
  translationData: TranslateToBookingResponse,
  vesselImo: number
): VesselTranslationData | undefined =>
  translationData.vessels.find((vessel) => vessel.vesselImo === vesselImo)

export default convertToBookingData
