import {
  AirportType,
  CityType,
  DepotType,
  HubType,
  PortType,
  RailStationType,
  SymbolicLocationType,
} from 'views/atlas/types/hub'

// eslint-disable-next-line import/prefer-default-export
export const HUB_TYPE_ICONS: Record<
  HubType,
  'anchor' | 'airport' | 'train' | 'inland' | 'package' | 'earth_locate'
> = {
  [PortType]: 'anchor',
  [AirportType]: 'airport',
  [RailStationType]: 'train',
  [CityType]: 'inland',
  [DepotType]: 'package',
  [SymbolicLocationType]: 'earth_locate',
}
