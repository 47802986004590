import React from 'react'
import { useTranslation } from 'react-i18next'

import useQueryParams from 'services/hooks/use_query_params'

import { SSOErrorContainer } from 'views/sso/ssoError.style'

interface SsoErrorPageQueryParams {
  error?: boolean
}

const SSOErrorPage: React.FC = () => {
  const { t, i18n } = useTranslation()

  const { error } = useQueryParams<SsoErrorPageQueryParams>()

  const messageKey = `users.sso.errors.${error}`

  return (
    <SSOErrorContainer>
      <h1>{t('users.sso.errors.title')}</h1>
      <p>{t('users.sso.errors.common-message')}</p>
      <p> {i18n.exists(messageKey) ? t(messageKey) : null}</p>
    </SSOErrorContainer>
  )
}

export default SSOErrorPage
