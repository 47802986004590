import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { Status, STATUS_FULFILLED, STATUS_PENDING, STATUS_REJECTED } from 'constants/api'
import useUrlParams from 'services/api/hooks/use_url_params'
import { selectCurrentUser } from 'views/iam/slices/iamSlice'
import { AtlasClient } from 'services/api/clients'
import onError from 'services/api/error'
import { Cluster, ClusterIndex, fetchClustersSchema } from 'views/atlas/types/cluster'
import { Tvalue } from 'components/select'

import type { RootState } from 'services/store/store'

interface FetchClustersParams {
  countries: string[]
  states: string[]
  search: string
  page: number
}

interface BaseCommandClusterParams {
  name: string
  nameAliases: string[]
  county: string | null
  timezone: string
  countryId: string
  stateId: string | null
}

type CreateClusterParams = BaseCommandClusterParams

interface UpdateClusterParams extends BaseCommandClusterParams {
  token: string
}

export const createCluster = createAsyncThunk<Cluster, CreateClusterParams, { state: RootState }>(
  'atlas/clusters/createCluster',
  async (data: CreateClusterParams, thunkAPI) => {
    const { getState } = thunkAPI
    const user = selectCurrentUser(getState())
    return AtlasClient.post('/clusters', data, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const updateCluster = createAsyncThunk<Cluster, UpdateClusterParams, { state: RootState }>(
  'atlas/clusters/updateCluster',
  async ({ token, ...payload }, thunkAPI) => {
    const { getState } = thunkAPI
    const user = selectCurrentUser(getState())
    return AtlasClient.put(`/clusters/${token}`, payload, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

const FETCH_CLUSTERS_PER = 50

export const fetchClusters = createAsyncThunk<
  ClusterIndex,
  FetchClustersParams,
  { state: RootState }
>(
  'atlas/clusters/fetchClusters',
  async ({ search, states, countries, page }: FetchClustersParams, thunkAPI) => {
    const { getState } = thunkAPI
    const user = selectCurrentUser(getState())
    const url = useUrlParams('/clusters', {
      q: search,
      states,
      countries,
      page,
      per: FETCH_CLUSTERS_PER,
    })
    return AtlasClient.get(url, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    }).then((r) => fetchClustersSchema.cast(r.data, { stripUnknown: true }))
  }
)

interface ClusterSummary {
  name: string
  token: string
}

const formatClusterName = (cluster: Cluster): string => {
  if (cluster.state) return `${cluster.name} (${cluster.state.name}, ${cluster.country.name})`
  return `${cluster.name} (${cluster.country.name})`
}

export const fetchClustersSummary = createAsyncThunk<
  ClusterSummary[],
  { value: Tvalue },
  { state: RootState }
>('atlas/clusters/fetchClustersSummary', async ({ value }: { value: Tvalue }, thunkAPI) => {
  const { getState } = thunkAPI
  const user = selectCurrentUser(getState())
  const url = useUrlParams('/clusters', { q: value })
  return AtlasClient.get(url, {
    headers: { Authorization: `Bearer ${user.accessToken}` },
  }).then((r) =>
    r.data.clusters.map((cluster: Cluster) => ({
      token: cluster.token,
      name: formatClusterName(cluster),
    }))
  )
})

export interface ClusterActiveFilters {
  countries: string[]
  states: string[]
  search: string
}

interface ClusterInitialState {
  status: Status
  totalCount: number
  activeFilters: ClusterActiveFilters
}

const clusterAdapter = createEntityAdapter<Cluster>({
  selectId: ({ token }: Cluster) => token,
})

const clusterInitialState = clusterAdapter.getInitialState<ClusterInitialState>({
  status: STATUS_PENDING,
  totalCount: 0,
  activeFilters: { countries: [], states: [], search: '' },
})

const clusterSlice = createSlice({
  name: 'clusters',
  initialState: clusterInitialState,
  reducers: {
    removeAll: clusterAdapter.removeAll,
    saveActiveFilters: (state, action) => {
      state.activeFilters = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClusters.fulfilled, (state, action) => {
      const { clusters, totalCount } = action.payload
      clusterAdapter.addMany(state, clusters)
      state.totalCount = totalCount
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchClusters.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchClusters.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
  },
})

export const { removeAll: removeAllClusters, saveActiveFilters: saveClustersActiveFilters } =
  clusterSlice.actions

export const { selectAll: selectClusters } = clusterAdapter.getSelectors(
  (state: RootState) => state.clusters
)
export const selectClustersStatus = (state: RootState) => state.clusters.status
export const selectClustersTotalCount = (state: RootState) => state.clusters.totalCount
export const selectClustersActiveFilters = (state: RootState) => state.clusters.activeFilters

export default clusterSlice.reducer
