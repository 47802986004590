export const APP_MODAL_NAMES = [
  'defaultModalName',
  'settings',
  'userIndexSettings',
  'profile',
  'search',
  'shipments',
  'upload',
  'widget',
  'network',
  'error',
  'dashboardMap',
  'uploadDocuments',
  'share',
  'bookingExchange',
  'bookingValidateEdit',
  'bookingValidateCreate',
  'bookingTemplateValidateApply',
  'bookingTemplateValidateEdit',
  'timelineDateDeclarationModal',
  'newMilestoneModal',
  'shipmentsExport',
  'newUser',
  'importUsers',
  'userActivation',
  'deleteShipment',
  'reportShipment',
  'editPath',
  'timelineModal',
  'legModal',
  'editMilestone',
  'clusterForm',
  'hubForm',
  'trustedRoutesConvertToBooking',
] as const

export type ModalName = typeof APP_MODAL_NAMES[number]
