import React from 'react'

import { StyledItem, StyledItemLink } from 'components/navigation_item/style'

interface NavigationItemProps {
  active?: boolean
  to: string | { pathname: string }
  text?: string
  variant?: 'dark' | 'light'
  target?: string
  testId?: string
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  active = false,
  to,
  target,
  text,
  variant = 'dark',
  testId,
}) => (
  <StyledItem $active={active} $variant={variant} data-testid={testId}>
    <StyledItemLink target={target} to={to}>
      {text}
    </StyledItemLink>
  </StyledItem>
)

export default NavigationItem
