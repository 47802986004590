import React from 'react'
import { useParams } from 'react-router-dom'

import { CategoryParams, Category } from 'views/atlas/types/category'
import ClusterIndex from 'views/atlas/clusters'
import HubIndex from 'views/atlas/hubs'
import AddressIndex from 'views/atlas/addresses'

const Atlas: React.FC = () => {
  const { category } = useParams<CategoryParams>()
  return (
    <>
      {category === Category.CLUSTER && <ClusterIndex />}
      {category === Category.HUB && <HubIndex />}
      {category === Category.ADDRESS && <AddressIndex />}
    </>
  )
}

export default Atlas
export { HUB_TYPE_ICONS } from 'views/atlas/helpers'
