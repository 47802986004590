import {
  AirportType,
  CityType,
  DepotType,
  HubType,
  PortType,
  RailStationType,
} from 'views/atlas/types/hub'
import { isArray, readonlyArrayIncludes } from 'services/helpers/values'
import { ApiError } from 'views/atlas/slices/hub'

export const allowLocodeFields = (hubType?: HubType | string | null): boolean =>
  readonlyArrayIncludes([PortType, AirportType, RailStationType, CityType], hubType)

export const expectLocodeFields = (hubType?: HubType | string | null): boolean =>
  readonlyArrayIncludes([PortType, AirportType, RailStationType], hubType)

export const expectClusterField = (hubType?: HubType | string | null): boolean =>
  readonlyArrayIncludes([PortType, AirportType, RailStationType, CityType, DepotType], hubType)

export const expectPositionsField = (hubType?: HubType | string | null): boolean =>
  readonlyArrayIncludes([PortType, AirportType, RailStationType, CityType, DepotType], hubType)

export const allowPositionsField = (hubType?: HubType | string | null): boolean =>
  readonlyArrayIncludes([PortType, AirportType, RailStationType, CityType, DepotType], hubType)

export const allowAirportFields = (hubType?: HubType | string | null): boolean =>
  hubType === AirportType

export const allowPostalCodeField = (hubType?: HubType | string | null): boolean =>
  hubType === CityType

export const formatApiError = (error: ApiError): string | null => {
  if (typeof error.message?.detail === 'string') {
    return error.message?.detail
  }
  if (isArray(error.message?.detail)) {
    return error.message?.detail.map((detail) => detail.msg).join(', ')
  }
  return null
}
