import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTheme } from 'styled-components'

import Modal from 'components/modal'
import Button from 'components/button'
import useModal from 'components/modal/hooks/use_modal'
import S from 'views/atlas/clusters/components/cluster_form/style'
import Input from 'components/input'
import { createCluster, updateCluster } from 'views/atlas/slices/cluster'
import Select from 'components/select'
import InputTags from 'components/input_tags'
import TIMEZONES from 'constants/timezone'
import ClusterFormData, {
  ClusterFormDataBeforeValidation,
  createClusterSchema,
} from 'views/atlas/clusters/components/cluster_form/type'
import Icon from 'components/icon'
import { StyledInputError } from 'components/input/style'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import { Cluster } from 'views/atlas/types/cluster'
import fetchStates from 'views/atlas/slices/state'
import fetchCountries from 'views/atlas/slices/country'
import { isNull } from 'services/helpers/values'
import { fetchHubs } from 'views/atlas/slices/hub'
import { Hub } from 'views/atlas/types/hub'
import { HUB_TYPE_ICONS } from 'views/atlas/helpers'

interface ClusterFormProps {
  callbackAfterSave: () => void
  cluster: Cluster | null
}

const ClusterForm: React.FC<ClusterFormProps> = (props) => {
  const { callbackAfterSave, cluster } = props
  const { setOpen } = useModal('clusterForm')
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const submitCreateCluster = (data: ClusterFormData) => {
    const payload = {
      name: data.name,
      nameAliases: data.nameAliases,
      county: data.county,
      timezone: data.timezone.value,
      countryId: data.country.value,
      stateId: data.state?.value || null,
    }
    dispatch(createCluster(payload))
      .unwrap()
      .then(() => callbackAfterSave())
      .then(() => setOpen(false))
      .catch((r: any) =>
        dispatch(
          addNotification({
            type: 'alert',
            title: 'Cluster Creation',
            text: r.message?.detail || 'an error occurred during the creation of the cluster',
          })
        )
      )
  }
  const submitUpdateCluster = (token: string, data: ClusterFormData) => {
    const payload = {
      name: data.name,
      nameAliases: data.nameAliases,
      county: data.county,
      timezone: data.timezone.value,
      countryId: data.country.value,
      stateId: data.state?.value || null,
      token,
    }
    dispatch(updateCluster(payload))
      .unwrap()
      .then(() => callbackAfterSave())
      .then(() => setOpen(false))
      .catch((r: any) =>
        dispatch(
          addNotification({
            type: 'alert',
            title: 'Cluster Edition',
            text: r.message?.detail || 'an error occurred during the edition of the cluster',
          })
        )
      )
  }

  const onSubmit = (data: ClusterFormData) => {
    if (cluster) {
      submitUpdateCluster(cluster.token, data)
    } else {
      submitCreateCluster(data)
    }
  }

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<ClusterFormDataBeforeValidation, undefined, ClusterFormData>({
    resolver: yupResolver(createClusterSchema),
    reValidateMode: 'onChange',
    defaultValues: cluster
      ? {
          name: cluster.name,
          nameAliases: cluster.nameAliases,
          county: cluster.county,
          timezone: cluster.timezone ? { label: cluster.timezone, value: cluster.timezone } : null,
          country: { label: cluster.country.name, value: cluster.country.token },
          state: cluster.state ? { label: cluster.state.name, value: cluster.state.token } : null,
        }
      : {
          name: '',
          nameAliases: [],
          county: null,
          timezone: null,
          country: null,
          state: null,
        },
  })

  const closeModal = () => {
    reset()
    setOpen(false)
  }
  const [hubs, setHubs] = useState<undefined | Hub[]>(undefined)
  useEffect(() => {
    if (isNull(cluster)) return

    dispatch(fetchHubs({ clusterToken: cluster.token }))
      .unwrap()
      .then((response) => {
        setHubs(response.hubs)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster])

  return (
    <Modal modalName='clusterForm' width='large'>
      <Modal.Header>
        {cluster ? t('atlas.actions.editCluster') : t('atlas.actions.newCluster')}
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <S.FormContent>
            <S.Field>
              <Input
                type='text'
                placeholder='e.g. Le Havre'
                {...register('name')}
                required
                label='name'
                error={errors.name?.message ? errors.name?.message : undefined}
              />
            </S.Field>
            <S.Field>
              <Controller
                name='nameAliases'
                render={({ field }) => (
                  <>
                    <InputTags
                      label='name aliases'
                      name={field.name}
                      onChange={({ tags }) => {
                        field.onChange(tags)
                      }}
                      value={field.value}
                    />
                    {/*
                    TODO: we should use the `error` props in the InputTags component,
                    but this generates the following error:
                    ```
                      Failed to execute 'toggle' on 'DOMTokenList':
                      The token provided ('sc-cVksOY kGPaLy') contains HTML space characters,
                      which are not valid in tokens.
                    ```
                     */}
                    {errors.nameAliases?.find && (
                      <StyledInputError>
                        <Icon name='warning' /> {errors.nameAliases.find((v) => !!v)?.message}
                      </StyledInputError>
                    )}
                  </>
                )}
                control={control}
              />
            </S.Field>
            <S.Field>
              <Input
                type='text'
                placeholder='e.g. Seine-Maritime'
                {...register('county')}
                label='county'
                error={errors.county?.message ? errors.county?.message : undefined}
              />
            </S.Field>
            <S.Field>
              <Controller
                name='country'
                control={control}
                render={({ field }) => (
                  <Select
                    error={errors.country?.message ? errors.country?.message : undefined}
                    label='country'
                    required
                    placeholder='e.g. France'
                    name={field.name}
                    isSearchable
                    async
                    value={field.value}
                    onChange={({ value }) => field.onChange(value)}
                    fetch={({ value }) => fetchCountries({ value })}
                    fetchOnFocus={() => fetchCountries({ value: null })}
                    fetchedOptionsFormat={(options) =>
                      options.map(({ name, token }: { name: string; token: string }) => ({
                        value: token,
                        label: name,
                      }))
                    }
                  />
                )}
              />
            </S.Field>
            <S.Field>
              <Controller
                name='state'
                control={control}
                render={({ field }) => (
                  <Select
                    label='state'
                    isClearable
                    placeholder='e.g. Normandie'
                    name={field.name}
                    isSearchable
                    async
                    value={field.value}
                    onChange={({ value }) => field.onChange(value)}
                    fetch={({ value }) => fetchStates({ value })}
                    fetchOnFocus={() => fetchStates({ value: null })}
                    fetchedOptionsFormat={(options) =>
                      options.map(({ name, token }: { name: string; token: string }) => ({
                        value: token,
                        label: name,
                      }))
                    }
                  />
                )}
              />
            </S.Field>
            <S.Field>
              <Controller
                name='timezone'
                control={control}
                render={({ field }) => (
                  <Select
                    error={errors.timezone?.message ? errors.timezone?.message : undefined}
                    label='timezone'
                    required
                    placeholder='e.g. Europe/Paris'
                    name={field.name}
                    isSearchable
                    value={field.value}
                    onChange={({ value }) => field.onChange(value)}
                    options={TIMEZONES.map((timezone) => ({ value: timezone, label: timezone }))}
                  />
                )}
              />
            </S.Field>
          </S.FormContent>
          {hubs && (
            <S.HubWrapper>
              <S.HubTitle>Linked Hubs</S.HubTitle>
              <>
                {hubs.map((hub) => (
                  <S.HubItem>
                    <Icon
                      width={24}
                      height={24}
                      name={HUB_TYPE_ICONS[hub.type]}
                      fill={theme.mediumGray}
                    />
                    {hub.name} {'locode' in hub ? `(${hub.locode})` : ''}
                  </S.HubItem>
                ))}
              </>
            </S.HubWrapper>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Button text={t('actions.cancel')} variant='clear' onClick={closeModal} />
          <Button text={t('actions.save')} variant='highlight' type='submit' disabled={!isDirty} />
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ClusterForm
