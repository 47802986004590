import React from 'react'
import { useTranslation } from 'react-i18next'

import { SSOSignOutContainer, StyledLink } from 'views/iam/style'
import Link from 'components/link'
import useQueryParams from 'services/hooks/use_query_params'

interface SsoSignOutQueryParams {
  loginUrl?: string
}

const SSOSignOut: React.FC = () => {
  const { t } = useTranslation()

  const { loginUrl } = useQueryParams<SsoSignOutQueryParams>()

  return (
    <SSOSignOutContainer>
      <h1> {t('iam.logout.title')} </h1>
      {loginUrl ? (
        <p>
          <Link to={loginUrl} onClick={() => {}}>
            <StyledLink> {t('iam.logout.login')}</StyledLink>
          </Link>
        </p>
      ) : null}
    </SSOSignOutContainer>
  )
}
export default SSOSignOut
