import styled from 'styled-components'

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 0 50px;
`

const Field = styled.div`
  width: 100%;
`

const HubWrapper = styled.div`
  border-top: 2px solid ${({ theme }) => theme.lightGray};
  padding-top: 10px;
  margin: 20px 50px 0;
`

const HubItem = styled.div`
  display: flex;
  gap: 10px;
`

const HubTitle = styled.div`
  text-align: center;
`

const S = { FormContent, Field, HubItem, HubTitle, HubWrapper }

export default S
