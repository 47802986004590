import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  StyledFormFooter,
  StyledPageBookingDetailBoxShadow,
  StyledPageBookingDetailInner,
} from 'views/booking/style'
import { StyledFooterButton } from 'views/booking/components/show/style'
import useBookingForm from 'views/booking/components/form/hooks/use_booking_form'
import ValidateEditModal from 'views/booking/components/edit/validate_edit_modal'
import BookingForm from 'views/booking/components/form'
import useBooking from 'views/booking/hooks/use_booking'
import useBookingWorkflow from 'views/booking/hooks/use_booking_workflow'

import useModal from 'components/modal/hooks/use_modal'
import Button from 'components/button'
import { TEST_ID_BOOKING_CANCEL_BUTTON, TEST_ID_BOOKING_EDIT_BUTTON } from 'tests/e2e/test_ids'

interface BookingEditFormProps {
  token: string
}

const BookingEditForm: FC<BookingEditFormProps> = ({ token }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { setOpen } = useModal('bookingValidateEdit')
  const [booking] = useBooking(token)
  const { filters, isValid, isEdited, editedQueryParams, wontTriggerReview } = useBookingForm({
    booking,
    isEdit: true,
  })

  const { canEditBooking } = useBookingWorkflow(token)

  useEffect(() => {
    if (!canEditBooking) {
      history.push('/')
    }
  }, [canEditBooking, history])

  return (
    <>
      <ValidateEditModal
        token={token}
        editedQueryParams={editedQueryParams}
        wontTriggerReview={wontTriggerReview}
      />
      <StyledPageBookingDetailBoxShadow>
        <StyledPageBookingDetailInner>
          <BookingForm filters={filters} />
        </StyledPageBookingDetailInner>
        <StyledFormFooter>
          <StyledFooterButton
            as={Button}
            text={t('actions.cancel')}
            onClick={() => history.push(`/booking/${token}`)}
            rounded
            variant='clear'
            testId={TEST_ID_BOOKING_CANCEL_BUTTON}
          />
          <StyledFooterButton
            as={Button}
            text={t('bookings.actions.saveModifications')}
            onClick={() => setOpen(true)}
            rounded
            icon='send'
            variant='highlight'
            disabled={!isEdited || !isValid}
            testId={TEST_ID_BOOKING_EDIT_BUTTON}
          />
        </StyledFormFooter>
      </StyledPageBookingDetailBoxShadow>
    </>
  )
}

export default BookingEditForm
