import styled from 'styled-components'

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const S = { LoaderWrapper }

export default S
