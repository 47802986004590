import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useQueryParams = <T,>() => {
  const { search } = useLocation()
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const entries = Array.from(searchParams.entries())
    return Object.fromEntries(entries) as T
  }, [search])
  return params
}

export default useQueryParams
