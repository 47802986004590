import styled from 'styled-components'

import { Link } from 'react-router-dom'

import { easeQuartOut } from 'styles/utils/ease'

import { text14Regular } from 'styles/utils/texts'

export const StyledArrow = styled.div<{ $active: boolean }>`
  width: 24px;
  height: 24px;
  fill: ${(props) => (props.$active ? 'white' : props.theme.mediumGray)};
`

export const StyledDropdown = styled.li`
  position: relative;
`

export const StyledTitle = styled.div`
  cursor: pointer;
`

export const StyledMenu = styled.ul`
  position: absolute;
  min-width: 150px;
  list-style-type: none;
  padding: 0;
  background-color: white;
  border-radius: 12px;
  z-index: 10;
  border: 1px solid ${(props) => props.theme.lightGray};
  top: calc(100% + 10px);
  transform: translate3d(-50%, 0, 0);
  left: 50%;
  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    width: 100%;
    height: 15px;
  }
`

export const StyledNavigationOptionLink = styled(Link)<{ $active: boolean }>`
  color: ${(props) => (props.$active ? props.theme.primary : props.theme.textLight)};
  ${text14Regular};
  white-space: nowrap;
  display: block;
  padding: 15px 20px;
  transition: color 0.5s ${easeQuartOut};

  &:hover {
    color: ${({ theme }) => theme.textDark};
  }
`

export const StyledNavigationOption = styled.li`
  position: relative;
  width: 100%;
  height: 100%;

  &:not(:last-child)::after {
    content: '';
    display: block;
    height: 1px;
    left: 12px;
    bottom: 0;
    width: calc(100% - 24px);
    background-color: ${(props) => props.theme.lightGray};
    position: absolute;
  }
`
