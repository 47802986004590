import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import ClipLoader from 'react-spinners/ClipLoader'

import * as IconList from 'assets/icons'

import Link from 'components/link'
import Icon from 'components/icon'
import IconTooltip from 'components/icon_tooltip'

import { StyledButton, StyledSpan, StyledButtonIcon } from 'components/button/style'

// TODO: REFACTOR: variant, have a size prop and a highlight prop to allow mix of variants and size/highlight props

const Button = ({
  to,
  target,
  text,
  textRegular,
  icon,
  onClick,
  variant,
  disabled,
  rounded,
  padded,
  className,
  isLink,
  type,
  iconHighlighted,
  ariaLabel,
  testId,
  iconType,
  iconText,
  iconSize,
  tooltipColor,
  isLoading,
}) => {
  const theme = useTheme()

  return (
    <StyledButton
      as={isLink ? Link : 'button'}
      to={isLink ? to : null}
      target={isLink ? target : null}
      testId={isLink ? testId : null}
      $variant={variant}
      $rounded={rounded}
      onClick={onClick}
      $hasLabel={!!text}
      className={className}
      type={type}
      disabled={disabled || isLoading}
      $disabled={disabled}
      $padded={padded}
      $iconHighlighted={iconHighlighted}
      data-testid={testId}
    >
      {!isLoading && icon && iconType === Icon && (
        <StyledButtonIcon as={iconType} name={icon} ariaLabel={ariaLabel} />
      )}
      {!isLoading && icon && iconType === IconTooltip && (
        <IconTooltip variant={icon} content={iconText} size={iconSize} color={tooltipColor} />
      )}

      <StyledSpan $textRegular={textRegular}>
        {isLoading ? (
          <ClipLoader loading size={20} aria-label='Loading Spinner' color={theme.primary} />
        ) : (
          text
        )}
      </StyledSpan>
    </StyledButton>
  )
}

Button.propTypes = {
  /** Aria Label */
  ariaLabel: PropTypes.string,
  /** Button label */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Optional icon name */
  icon: PropTypes.oneOfType([...Object.keys(IconList), PropTypes.node]),
  /** Variant */
  variant: PropTypes.oneOf([
    'default',
    'highlight',
    'clear',
    'transparent',
    'icon',
    'link',
    'smallIcon',
    'verySmallIcon',
    'outline',
    'redOutline',
    'verySmallIcon',
    'minimalIcon',
  ]),
  /** Rounded - Available only when there is an icon but no label */
  rounded: PropTypes.bool,
  /** className */
  className: PropTypes.string,
  /** type */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** disabled */
  disabled: PropTypes.bool,
  /** On click event */
  onClick: PropTypes.func,
  /** isLink */
  isLink: PropTypes.bool,
  /** Adds extra padding */
  padded: PropTypes.bool,
  /** to */
  to: PropTypes.oneOfType([
    PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ pathname: PropTypes.string.isRequired, state: PropTypes.any }),
  ]),
  /** fills button for on going action */
  iconHighlighted: PropTypes.bool,
  /** Id for testing purposes */
  testId: PropTypes.string,
  /** Target of the link */
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top', 'framename']),
  iconType: PropTypes.oneOf([Icon, IconTooltip]),
  iconText: PropTypes.string,
  iconSize: PropTypes.string,
  tooltipColor: PropTypes.string,
  isLoading: PropTypes.bool,
  textRegular: PropTypes.bool,
}

Button.defaultProps = {
  ariaLabel: undefined,
  text: undefined,
  to: undefined,
  target: '_blank',
  icon: undefined,
  onClick: undefined,
  className: undefined,
  type: 'button',
  variant: 'default',
  disabled: false,
  rounded: false,
  padded: false,
  isLink: false,
  iconHighlighted: false,
  testId: undefined,
  iconType: Icon,
  iconText: undefined,
  iconSize: undefined,
  tooltipColor: undefined,
  isLoading: false,
  textRegular: false,
}

export default Button
