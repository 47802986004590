import { APP_STAGE_PROD, APP_STAGE_SANDBOX, APP_STAGE_STAGING, AppStage } from 'constants/stages'

export const BUBBLE_STAGING = 'Staging' as const
export const BUBBLE_SANDBOX = 'Sandbox' as const
export const BUBBLE_PRODUCTION = 'Production' as const

export const BUBBLE_ENVIRONMENTS = [BUBBLE_STAGING, BUBBLE_SANDBOX, BUBBLE_PRODUCTION] as const

export const WAKEO_BUBBLE_MAPPING = {
  [APP_STAGE_STAGING]: BUBBLE_STAGING,
  [APP_STAGE_SANDBOX]: BUBBLE_SANDBOX,
  [APP_STAGE_PROD]: BUBBLE_PRODUCTION,
}

const mapBubbleEnvironment = (appStage: AppStage) => WAKEO_BUBBLE_MAPPING[appStage]

export default mapBubbleEnvironment
