import React from 'react'

import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Hub, HubType, possibleHubTypes } from 'views/atlas/types/hub'
import useModal from 'components/modal/hooks/use_modal'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import Modal from 'components/modal'
import Button from 'components/button'
import S from 'views/atlas/hubs/components/hub_form/style'
import Input from 'components/input'
import HubFormData, {
  HubFormDataBeforeValidation,
  saveHubSchema,
} from 'views/atlas/hubs/components/hub_form/type'
import Select from 'components/select'
import { isPresent, round } from 'services/helpers/values'
import InputTags from 'components/input_tags'
import { StyledInputError } from 'components/input/style'
import Icon from 'components/icon'
import { fetchClustersSummary } from 'views/atlas/slices/cluster'
import { addNotification } from 'views/notifications/slice'
import {
  ApiError,
  createHub,
  CreateHubParams,
  updateHub,
  UpdateHubParams,
} from 'views/atlas/slices/hub'
import {
  allowAirportFields,
  allowLocodeFields,
  allowPositionsField,
  allowPostalCodeField,
  expectClusterField,
  expectLocodeFields,
  expectPositionsField,
  formatApiError,
} from 'views/atlas/hubs/components/hub_form/helper'

interface HubFormProps {
  callbackAfterSave: () => void
  hub: Hub | null
}

const MAX_POSITION_DECIMALS = 6

const normalizePosition = (
  longitude: number | undefined | null,
  latitude: number | undefined | null
): { longitude: number; latitude: number } | null => {
  if (isPresent(longitude) && isPresent(latitude)) {
    return {
      longitude: round(longitude, MAX_POSITION_DECIMALS),
      latitude: round(latitude, MAX_POSITION_DECIMALS),
    }
  }
  return null
}

const HubForm: React.FC<HubFormProps> = (props) => {
  const { callbackAfterSave, hub } = props
  const { setOpen } = useModal('hubForm')
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isDirty },
    watch,
    resetField,
  } = useForm<HubFormDataBeforeValidation, undefined, HubFormData>({
    resolver: yupResolver(saveHubSchema),
    reValidateMode: 'onChange',
    defaultValues: hub
      ? {
          cluster:
            'cluster' in hub ? { label: hub.cluster?.name, value: hub.cluster?.token } : null,
          name: hub.name,
          type: { label: hub.type, value: hub.type },
          iataCode: 'iataCode' in hub ? hub.iataCode || '' : '',
          icaoCode: 'icaoCode' in hub ? hub.icaoCode || '' : '',
          locode: 'locode' in hub ? hub.locode || '' : '',
          locodeAliases: 'locodeAliases' in hub ? hub.locodeAliases : [],
          postalCode: 'postalCode' in hub ? hub.postalCode || '' : '',
          position:
            'position' in hub
              ? { longitude: hub.position?.longitude, latitude: hub.position?.latitude }
              : { longitude: null, latitude: null },
        }
      : {
          cluster: null,
          name: '',
          type: null,
          iataCode: '',
          icaoCode: '',
          locode: '',
          locodeAliases: [],
          postalCode: '',
          position: { longitude: null, latitude: null },
        },
  })

  const submitCreateHub = (data: CreateHubParams) => {
    dispatch(createHub(data))
      .unwrap()
      .then(() => callbackAfterSave())
      .then(() => setOpen(false))
      .catch((e: ApiError) => {
        const error = formatApiError(e)
        return dispatch(
          addNotification({
            type: 'alert',
            title: 'Hub Creation',
            text: error || 'an error occurred during the creation of the hub',
          })
        )
      })
  }

  const submitUpdateHub = (data: UpdateHubParams) => {
    dispatch(updateHub(data))
      .unwrap()
      .then(() => callbackAfterSave())
      .then(() => setOpen(false))
      .catch((e: ApiError) => {
        const error = formatApiError(e)
        return dispatch(
          addNotification({
            type: 'alert',
            title: 'Hub Edition',
            text: error || 'an error occurred during the edition of the hub',
          })
        )
      })
  }

  const onSubmit = (data: HubFormData) => {
    const payload = {
      name: data.name,
      type: data.type.value,
      ...(allowPositionsField(data.type.value) && {
        position: normalizePosition(data.position.longitude, data.position.latitude),
      }),
      ...(allowAirportFields(data.type.value) && {
        iataCode: data.iataCode,
        icaoCode: data.icaoCode,
      }),
      ...(allowPostalCodeField(data.type.value) && { postalCode: data.postalCode || null }),
      ...(allowLocodeFields(data.type.value) && {
        locode: data.locode || null,
        locodeAliases: data.locodeAliases,
      }),
      clusterId: data.cluster ? data.cluster.value : null,
    }
    if (hub) {
      submitUpdateHub({ token: hub.token, ...payload })
    } else {
      submitCreateHub(payload)
    }
  }
  const closeModal = () => {
    reset()
    setOpen(false)
  }

  const typeSelected = watch('type')

  return (
    <Modal modalName='hubForm' width='large'>
      <Modal.Header>{hub ? t('atlas.actions.editHub') : t('atlas.actions.newHub')}</Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <S.FormContent>
            <S.Field>
              <Controller
                name='type'
                control={control}
                render={({ field }) => (
                  <Select
                    label='type'
                    required
                    name={field.name}
                    isSearchable
                    value={field.value}
                    isDisabled={isPresent(hub)}
                    onChange={({ value }) => {
                      if (!allowAirportFields(value?.value as HubType)) {
                        resetField('iataCode')
                        resetField('icaoCode')
                      }
                      if (!allowLocodeFields(value?.value as HubType)) {
                        resetField('locode')
                        resetField('locodeAliases')
                      }
                      if (!allowPostalCodeField(value?.value as HubType)) {
                        resetField('postalCode')
                      }
                      field.onChange(value)
                    }}
                    options={possibleHubTypes.map((possibleHubType) => ({
                      value: possibleHubType,
                      label: possibleHubType,
                    }))}
                  />
                )}
              />
            </S.Field>

            {isPresent(typeSelected) && (
              <>
                <S.Field>
                  <Controller
                    name='cluster'
                    control={control}
                    render={({ field }) => (
                      <Select
                        error={errors.cluster?.message ? errors.cluster?.message : undefined}
                        label='cluster'
                        required={expectClusterField(typeSelected.value)}
                        name={field.name}
                        isSearchable
                        isClearable={!expectClusterField(typeSelected.value)}
                        async
                        value={field.value}
                        onChange={({ value }) => field.onChange(value)}
                        fetch={({ value }) => fetchClustersSummary({ value })}
                        fetchOnFocus={() => fetchClustersSummary({ value: null })}
                        fetchedOptionsFormat={(options) =>
                          options.map(({ name, token }: { name: string; token: string }) => ({
                            value: token,
                            label: name,
                          }))
                        }
                      />
                    )}
                  />
                </S.Field>
                <S.Field>
                  <Input
                    type='text'
                    placeholder='e.g. Paris'
                    {...register('name')}
                    required
                    label='name'
                    error={errors.name?.message ? errors.name?.message : undefined}
                  />
                </S.Field>
                {allowPositionsField(typeSelected.value) && (
                  <S.Field>
                    <S.Coordinate>
                      <Input
                        type='number'
                        {...register('position.latitude')}
                        required={expectPositionsField(typeSelected.value)}
                        label='latitude'
                        step='any'
                        error={
                          errors.position?.latitude?.message
                            ? errors.position.latitude.message
                            : undefined
                        }
                      />
                      <Input
                        type='number'
                        {...register('position.longitude')}
                        required={expectPositionsField(typeSelected.value)}
                        label='longitude'
                        step='any'
                        error={
                          errors.position?.longitude?.message
                            ? errors.position.longitude.message
                            : undefined
                        }
                      />
                    </S.Coordinate>
                  </S.Field>
                )}
                {allowLocodeFields(typeSelected.value) && (
                  <>
                    <S.Field>
                      <Input
                        type='text'
                        placeholder='e.g. FRCDG'
                        {...register('locode')}
                        required={expectLocodeFields(typeSelected.value)}
                        label='locode'
                        error={errors.locode?.message ? errors.locode?.message : undefined}
                      />
                    </S.Field>
                    <S.Field>
                      <Controller
                        name='locodeAliases'
                        render={({ field }) => (
                          <>
                            <InputTags
                              label='locode aliases'
                              name={field.name}
                              onChange={({ tags }) => field.onChange(tags)}
                              value={field.value}
                            />
                            {/*
                            TODO: we should use the `error` props in the InputTags component,
                            but this generates the following error:
                            ```
                              Failed to execute 'toggle' on 'DOMTokenList':
                              The token provided ('sc-cVksOY kGPaLy') contains HTML space characters,
                              which are not valid in tokens.
                            ```
                             */}
                            {errors.locodeAliases?.find && (
                              <StyledInputError>
                                <Icon name='warning' />{' '}
                                {errors.locodeAliases.find((v) => !!v)?.message}
                              </StyledInputError>
                            )}
                          </>
                        )}
                        control={control}
                      />
                    </S.Field>
                  </>
                )}
                {allowPostalCodeField(typeSelected.value) && (
                  <S.Field>
                    <Input
                      type='text'
                      placeholder='e.g. 95700'
                      {...register('postalCode')}
                      label='postalCode'
                      error={errors.postalCode?.message ? errors.postalCode?.message : undefined}
                    />
                  </S.Field>
                )}
                {allowAirportFields(typeSelected.value) && (
                  <>
                    <S.Field>
                      <Input
                        type='text'
                        placeholder='e.g. CDG'
                        {...register('iataCode')}
                        required
                        label='iataCode'
                        error={errors.iataCode?.message ? errors.iataCode?.message : undefined}
                      />
                    </S.Field>
                    <S.Field>
                      <Input
                        type='text'
                        placeholder='e.g. LFPG'
                        {...register('icaoCode')}
                        label='icaoCode'
                        error={errors.icaoCode?.message ? errors.icaoCode?.message : undefined}
                      />
                    </S.Field>
                  </>
                )}
              </>
            )}
          </S.FormContent>
        </Modal.Content>

        <Modal.Footer>
          <Button text={t('actions.cancel')} variant='clear' onClick={closeModal} />
          <Button text={t('actions.save')} variant='highlight' type='submit' disabled={!isDirty} />
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default HubForm
