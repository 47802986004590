import { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Bugsnag from '@bugsnag/js'

import { isNull } from 'services/helpers/values'
import DateHelper from 'services/helpers/date_helper'
import { routeBookingCreate } from 'services/helpers/routes'
import useAppDispatch from 'services/hooks/use_app_dispatch'

import { TrustedRoute } from 'views/trusted_routes/types/trusted_route'
import { allLocodes, allVesselImos } from 'views/trusted_routes/helpers'
import {
  TranslateToBookingResponse,
  translateTrustedRouteToBooking,
} from 'views/trusted_routes/slice'
import convertToBookingData from 'views/trusted_routes/components/convert_to_booking/helpers'

const MINIMUM_DAYS_BEFORE_MANUAL_VALIDATION = 2

interface UseConvertToBookingReturn {
  triggerConvertion: () => void
  state: { convertionPending: boolean; convertionRejected: boolean }
}

const useConvertToBooking = (trustedRoute: TrustedRoute): UseConvertToBookingReturn => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [convertionPending, setConvertionPending] = useState<boolean>(false)
  const [convertionRejected, setConvertionRejected] = useState<boolean>(false)

  const goToBooking = () => {
    const locodes = allLocodes(trustedRoute)
    const vesselImos = allVesselImos(trustedRoute)
    const carrierScac = trustedRoute.scac
    dispatch(
      translateTrustedRouteToBooking({
        addressPortLocodes: locodes,
        vesselImos,
        carrierScacs: [carrierScac],
      })
    )
      .unwrap()
      .then((translationData: TranslateToBookingResponse) =>
        convertToBookingData(trustedRoute, translationData)
      )
      .then((formatedData) => {
        const routingWarning =
          formatedData.untranslatedAddresses.length > 0
            ? {
                message: t('trustedRoutes.convertToBooking.routingWarning'),
                addresses: formatedData.untranslatedAddresses,
              }
            : undefined
        history.push(routeBookingCreate(), {
          booking: formatedData.booking,
          routingWarning,
        })
      })
      .catch((e) => {
        Bugsnag.notify(e)
        setConvertionPending(false)
        setConvertionRejected(true)
      })
  }

  const dateHelper = new DateHelper(trustedRoute.cutOffDate)
  const dayBeforeCutOffDate: number = dateHelper.diff(new Date(), 'days')
  useEffect(() => {
    if (
      (isNull(trustedRoute.cutOffDate) ||
        dayBeforeCutOffDate > MINIMUM_DAYS_BEFORE_MANUAL_VALIDATION) &&
      !convertionPending
    ) {
      setConvertionPending(true)
    }
  }, [dayBeforeCutOffDate, convertionPending, trustedRoute.cutOffDate])

  useEffect(() => {
    if (convertionPending) goToBooking()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertionPending])

  const triggerConvertion = () => setConvertionPending(true)

  return { state: { convertionPending, convertionRejected }, triggerConvertion }
}

export default useConvertToBooking
