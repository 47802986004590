import React, { useContext, useState, useCallback, useMemo, FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useOnce from 'services/hooks/use_once'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { FormContext } from 'services/providers/form_provider'
import { isNull, isPresent, omitKeys } from 'services/helpers/values'
import { TAnyFilter } from 'services/hooks/use_filter'

import Icon from 'components/icon'
import SavedTemplatesItem from 'components/booking_templates_saved_item'
import Input from 'components/input'
import Placeholder from 'components/placeholder'
import BookingFiltersSavedSkeleton from 'components/booking_templates_saved_item/skeleton'

import { StyledBookingCreateList } from 'views/booking/components/create/style'

import {
  BookingTemplate,
  AnyBookingMerchandise,
  BookingAddress,
  BookingMerchandiseContainerContent,
  BookingMerchandiseTotal,
  BookingMerchandisePackageContent,
  BookingSelectValue,
  CommonMerchandiseDetails,
  VesselSelectValue,
} from 'views/booking/slices/types'
import {
  createBookingTemplate,
  deleteBookingTemplate,
  fetchBookingTemplates,
  selectBookingTemplates,
  selectBookingTemplatesStatus,
  updateBookingTemplate,
} from 'views/booking/slices/booking_templates_slice'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import { addNotification } from 'views/notifications/slice'
import {
  AnyBookingFormFilter,
  IBookingFormFilters,
} from 'views/booking/components/form/hooks/use_booking_form'
import ValidateTemplateModal from 'views/booking/components/create/template_modal'
import ValidateEditTemplateModal from 'views/booking/components/create/validate_edit_template_modal'

import { STATUS_FULFILLED } from 'constants/api'
import Sidebar from 'components/sidebar'
import S from 'components/filters_sidebar/style'
import { StyledSavedTemplatesEdit } from 'components/booking_templates_saved_item/style'
import {
  getTestIdForBookingTemplateItem,
  TEST_ID_BOOKING_TEMPLATE_INPUT,
  TEST_ID_BOOKING_TEMPLATE_SAVE,
} from 'tests/e2e/test_ids'
import { MERCHANDISE_FCL, MERCHANDISE_TYPE_TOTAL } from 'constants/bookings'
import useModal from 'components/modal/hooks/use_modal'

const BookingTemplates: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const templates = useSelector(selectBookingTemplates)
  const { filtersHash, queryParams } = useContext(FormContext)
  const { setOpen: setOpenValidateApply } = useModal('bookingTemplateValidateApply')
  const { setOpen: setOpenValidateEdit } = useModal('bookingTemplateValidateEdit')
  const status = useSelector(selectBookingTemplatesStatus)
  const { s } = useStaticLocales()

  const [templateName, setTemplateName] = useState<string>('')
  const [editMode, setEditMode] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<BookingTemplate | undefined>(undefined)

  const createTemplateQueryParams = () => {
    const q = omitKeys(queryParams, ['pol_ptd', 'pod_pta', 'ptd', 'pta']) as any
    q.transshipments = q.transshipments
      .filter(({ address_id }: any) => isPresent(address_id))
      .map(({ address_id }: any) => ({
        address_id,
      }))

    // Hackeo
    // We want to send null instead of undefined
    // in order to erase values to nil when editing a template
    const queryParamsNulled = Object.fromEntries(
      Object.entries(q).map(([key, value]: any) => [key, isPresent(value) ? value : null])
    )
    return queryParamsNulled
  }

  const onTemplateSubmit = () => {
    dispatch(createBookingTemplate({ ...createTemplateQueryParams(), templateName }))
      .unwrap()
      .then(() => {
        setTemplateName('')
        dispatch(
          addNotification({
            type: 'success',
            title: t('bookings.templates.notifications.title'),
            text: t('bookings.templates.notifications.success'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('bookings.templates.notifications.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  const onTemplateEdit = () => {
    dispatch(
      updateBookingTemplate({
        id: selectedTemplate!.id,
        changes: createTemplateQueryParams(),
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('bookings.templates.notifications.title'),
            text: t('bookings.templates.notifications.edit'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('bookings.templates.notifications.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  const applyTemplate = useCallback(
    (template: BookingTemplate) => {
      // TODO: Refactor: the convert functions are duplicated in useBookingForm for the default values
      const convertBookingSelectValue = (raw?: BookingSelectValue | VesselSelectValue) =>
        raw
          ? {
              value: raw?.id,
              label: raw?.name,
            }
          : undefined

      const convertString = (raw?: string) =>
        raw
          ? {
              value: raw,
              label: raw,
            }
          : undefined

      const convertAddress = (raw?: BookingAddress) =>
        raw && isPresent(raw.name)
          ? {
              value: raw?.id,
              label: `${raw.name}, ${raw.countryCode} ${
                isPresent(raw.locode) ? `(${raw.locode})` : ''
              }`,
            }
          : undefined

      const fromStaticToSelectOptions = (path: string) =>
        Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

      const convertMerchandiseContent = (raw?: AnyBookingMerchandise) => {
        switch (raw?.merchandiseType) {
          case 'container': {
            const content: BookingMerchandiseContainerContent[] = raw.content || []
            return {
              container: content.map((c) => ({
                ...c,
                containerType: {
                  label: s('containerTypes')[c.containerType],
                  value: `${c.containerType}`,
                },
                products: c.products?.map((product) => ({
                  ...product,
                  commercialValue: {
                    amount: product.commercialValue?.amount,
                    currencyCode: {
                      label: product.commercialValue?.currencyCode,
                      value: product.commercialValue?.currencyCode,
                    },
                  },
                  hazardousGoods: {
                    ...product.hazardousGoods,
                    hazardousClass: fromStaticToSelectOptions('hazardousGoods').find(
                      ({ value }) => value === `${product.hazardousGoods?.hazardousClass}`
                    ),
                    packingGroup: fromStaticToSelectOptions('packingGroups').find(
                      ({ value }) => value === `${product.hazardousGoods?.packingGroup}`
                    ),
                  },
                })),
              })),
              package: [],
              packageTotal: { content: [] },
              total: { containers: [], products: [] },
            }
          }
          case 'package_total': {
            const content: CommonMerchandiseDetails[] = raw.content || []
            return {
              container: [],
              package: [],
              total: { containers: [], products: [] },
              packageTotal: {
                packageNumber: raw.packageNumber,
                weight: {
                  value: raw.weight?.value,
                  unit: raw.weight?.unit,
                },
                volume: {
                  value: raw.volume?.value,
                  unit: raw.volume?.unit,
                },
                content: content.map((merchandiseDetails) => ({
                  ...merchandiseDetails,
                  commercialValue: {
                    amount: merchandiseDetails.commercialValue?.amount,
                    currencyCode: {
                      label: merchandiseDetails.commercialValue?.currencyCode,
                      value: merchandiseDetails.commercialValue?.currencyCode,
                    },
                  },
                  hazardousGoods: {
                    ...merchandiseDetails.hazardousGoods,
                    hazardousClass: fromStaticToSelectOptions('hazardousGoods').find(
                      ({ value }) =>
                        value === `${merchandiseDetails.hazardousGoods?.hazardousClass}`
                    ),
                    packingGroup: fromStaticToSelectOptions('packingGroups').find(
                      ({ value }) => value === `${merchandiseDetails.hazardousGoods?.packingGroup}`
                    ),
                  },
                })),
              },
            }
          }
          case 'package': {
            const content: BookingMerchandisePackageContent[] = raw.content || []
            return {
              package: content?.map((p) => ({
                ...p,
                packageType: {
                  label: s('packageTypes')[(p as any).packageType],
                  value: p.packageType,
                },
                commercialValue: {
                  amount: p.commercialValue?.amount,
                  currencyCode: {
                    label: p.commercialValue?.currencyCode,
                    value: p.commercialValue?.currencyCode,
                  },
                },
                hazardousGoods: {
                  ...p.hazardousGoods,
                  hazardousClass: fromStaticToSelectOptions('hazardousGoods').find(
                    ({ value }) => value === `${p.hazardousGoods?.hazardousClass}`
                  ),
                  packingGroup: fromStaticToSelectOptions('packingGroups').find(
                    ({ value }) => value === `${p.hazardousGoods?.packingGroup}`
                  ),
                },
              })),
              container: [],
              total: { containers: [], products: [] },
              packageTotal: { content: [] },
            }
          }
          case 'total': {
            const { content }: BookingMerchandiseTotal = raw
            return {
              container: [],
              package: [],
              packageTotal: { content: [] },
              total: {
                containers:
                  content?.containers.map((container) => ({
                    ...container,
                    containerType: {
                      label: s('containerTypes')[(container as any).containerType],
                      value: container.containerType,
                    },
                  })) ?? [],
                products:
                  content?.products?.map((product) => ({
                    ...product,
                    commercialValue: {
                      amount: product.commercialValue?.amount,
                      currencyCode: {
                        label: product.commercialValue?.currencyCode,
                        value: product.commercialValue?.currencyCode,
                      },
                    },
                    hazardousGoods: {
                      ...product.hazardousGoods,
                      hazardousClass: fromStaticToSelectOptions('hazardousGoods').find(
                        ({ value }) => value === `${product.hazardousGoods?.hazardousClass}`
                      ),
                      packingGroup: fromStaticToSelectOptions('packingGroups').find(
                        ({ value }) => value === `${product.hazardousGoods?.packingGroup}`
                      ),
                    },
                  })) ?? [],
              },
            }
          }
          default:
            return {
              container: [],
              package: [],
              total: {
                containers: [],
                products: [],
              },
              packageTotal: { content: [] },
            }
        }
      }

      const preCarriageMatchesPol = template.preCarriageAddress?.id === template.pol?.id
      const onCarriageMatchesPod = template.onCarriageAddress?.id === template.pod?.id

      const translated: Record<
        keyof IBookingFormFilters,
        { filter: AnyBookingFormFilter; value: TAnyFilter }
      > = {
        clientReferenceFilter: {
          filter: filtersHash.clientReference,
          value: template.clientReference,
        },
        clientBookingNumberFilter: {
          filter: filtersHash.clientBookingNumber,
          value: template.clientBookingNumber,
        },
        forwarderReferenceFilter: {
          filter: filtersHash.forwarderReference,
          value: template.forwarderReference,
        },
        customReferencesFilter: {
          filter: filtersHash.customReferences,
          value: template.customReferences,
        },
        consignorFilter: {
          filter: filtersHash.consignor,
          value: convertBookingSelectValue(template.consignor),
        },
        consigneeFilter: {
          filter: filtersHash.consignee,
          value: convertBookingSelectValue(template.consignee),
        },
        forwarderFilter: {
          filter: filtersHash.forwarder,
          value: convertBookingSelectValue(template.forwarder),
        },
        shipperFilter: {
          filter: filtersHash.shipper,
          value: convertBookingSelectValue(template.shipper),
        },
        incotermsFilter: {
          filter: filtersHash.incoterms,
          value: convertString(template.incoterms),
        },
        incotermsLocationFilter: {
          filter: filtersHash.incotermsLocation,
          value: template.incotermsLocation,
        },
        transportTypeFilter: { filter: filtersHash.transportType, value: template.transportType },
        preCarriageFilter: {
          filter: filtersHash.preCarriage,
          value: preCarriageMatchesPol ? undefined : convertAddress(template.preCarriageAddress),
        },
        polFilter: {
          filter: filtersHash.pol,
          value: convertAddress(template.pol),
        },
        podFilter: {
          filter: filtersHash.pod,
          value: convertAddress(template.pod),
        },
        onCarriageFilter: {
          filter: filtersHash.onCarriage,
          value: onCarriageMatchesPod ? undefined : convertAddress(template.onCarriageAddress),
        },
        transportPlanFilter: {
          filter: filtersHash.transportPlan,
          value: {
            withPreCarriage: isPresent(template.preCarriageAddress) && !preCarriageMatchesPol,
            withPol: isPresent(template.pol) || isNull(template.preCarriageAddress),
            withPod: isPresent(template.pod) || isNull(template.onCarriageAddress),
            withOnCarriage: isPresent(template.onCarriageAddress) && !onCarriageMatchesPod,
          },
        },
        vgmCutOffDateFilter: {
          filter: filtersHash.vgmCutOffDate,
          value: undefined,
        },
        vesselCutOffDateFilter: {
          filter: filtersHash.vesselCutOffDate,
          value: undefined,
        },
        // Custom fields are set only on edit, thus they are not present on a template
        customFieldsFilter: {
          filter: filtersHash.customFields,
          value: [],
        },
        merchandiseFilter: {
          filter: filtersHash.merchandise,
          value: {
            ...convertMerchandiseContent(template.merchandise),
            shippingMode: template.merchandise?.shippingMode ?? MERCHANDISE_FCL,
            merchandiseType: template.merchandise?.merchandiseType ?? MERCHANDISE_TYPE_TOTAL,
          },
        },
        rateConfirmationFilter: {
          filter: filtersHash.rateConfirmation,
          value: template.rateConfirmation,
        },
        keyContactsFilter: {
          filter: filtersHash.keyContacts,
          value: template.assignedUsers.map(({ email }) => email),
        },
        commentsFilter: { filter: filtersHash.comments, value: template.comments },
        forwarderCommentFilter: {
          filter: filtersHash.forwarderComment,
          value: template.forwarderComment,
        },
        // Templates don't have dates
        ptdFilter: { filter: filtersHash.ptd, value: undefined },
        ptaFilter: { filter: filtersHash.pta, value: undefined },
        polPtdFilter: { filter: filtersHash.polPtd, value: undefined },
        polPtaFilter: { filter: filtersHash.polPta, value: undefined },
        podPtdFilter: { filter: filtersHash.podPtd, value: undefined },
        podPtaFilter: { filter: filtersHash.podPta, value: undefined },
        carrierFilter: {
          filter: filtersHash.carrier,
          value: convertBookingSelectValue(template.carrier),
        },
        bookingNumberFilter: {
          filter: filtersHash.bookingNumber,
          value: template.bookingNumber,
        },
        masterBlFilter: { filter: filtersHash.masterBl, value: template.masterBl },
        voyageNumbersFilter: { filter: filtersHash.voyageNumbers, value: template.voyageNumbers },
        flightNumbersFilter: { filter: filtersHash.flightNumbers, value: template.flightNumbers },
        vesselsFilter: {
          filter: filtersHash.vessels,
          value: template.vessels.map(convertBookingSelectValue),
        },
        shipmentAttributesFilter: {
          filter: filtersHash.shipmentAttributes,
          value: template.shipmentAttributes,
        },
        transshipmentsFilter: {
          filter: filtersHash.transshipments,
          value: template.transshipments?.map(({ address }: any) => ({
            address: convertAddress(address),
          })),
        },
        rateAmountFilter: {
          filter: filtersHash.rateAmount,
          value: undefined,
        },
        rateCurrencyFilter: {
          filter: filtersHash.rateCurrency,
          value: undefined,
        },
      }
      Object.values(translated)
        .filter(({ filter, value }) => isPresent(value) && isPresent(filter))
        .forEach(({ filter, value }) => {
          filter.setValue(value)
        })

      dispatch(
        addNotification({
          type: 'success',
          title: t('bookings.templates.notifications.title'),
          text: t('bookings.templates.notifications.apply'),
        })
      )
    },
    [filtersHash, dispatch, t, s]
  )

  const deleteTemplate = useCallback(
    (template: BookingTemplate) => {
      dispatch(deleteBookingTemplate(template))
        .unwrap()
        .then(() =>
          dispatch(
            addNotification({
              type: 'success',
              title: t('bookings.templates.notifications.title'),
              text: t('bookings.templates.notifications.delete'),
            })
          )
        )
        .catch(() =>
          dispatch(
            addNotification({
              type: 'alert',
              title: t('bookings.templates.notifications.title'),
              text: t('errors.notification.content'),
            })
          )
        )
    },
    [dispatch, t]
  )

  const items = useMemo(
    () =>
      templates.map((template, index) => (
        <SavedTemplatesItem
          key={template.id}
          label={template.templateName}
          editMode={editMode}
          onClickDelete={() => deleteTemplate(template)}
          onClickItem={() => {
            setSelectedTemplate(template)
            setOpenValidateApply(true)
          }}
          onClickEdit={() => {
            setSelectedTemplate(template)
            setOpenValidateEdit(true)
          }}
          testId={getTestIdForBookingTemplateItem(index)}
        />
      )),
    [templates, editMode, deleteTemplate, setOpenValidateApply, setOpenValidateEdit]
  )

  useOnce(() => {
    dispatch(fetchBookingTemplates())
  })

  return (
    <Sidebar>
      <ValidateTemplateModal
        onValidate={() => {
          if (selectedTemplate !== undefined) {
            applyTemplate(selectedTemplate)
          }
        }}
        onClose={() => {
          setSelectedTemplate(undefined)
        }}
      />

      <ValidateEditTemplateModal
        onValidate={() => {
          if (selectedTemplate !== undefined) {
            onTemplateEdit()
          }
        }}
        onClose={() => {
          setSelectedTemplate(undefined)
        }}
        templateName={selectedTemplate?.templateName}
      />

      <Sidebar.Header>
        <S.Header>
          <S.HeaderIcon as={Icon} name='settings' />
          <Sidebar.Title>{t('bookings.templates.title')}</Sidebar.Title>
        </S.Header>
      </Sidebar.Header>

      <Sidebar.Content>
        <S.Section>
          <Input
            type='text'
            placeholder={t('bookings.templates.placeholder')}
            name='template_name'
            button={{
              text: t('actions.save'),
              icon: 'save_outline',
              onClick: onTemplateSubmit,
              disabled: !templateName,
              testId: TEST_ID_BOOKING_TEMPLATE_SAVE,
            }}
            value={templateName}
            onChange={({ target: { value } }) => setTemplateName(value)}
            testId={TEST_ID_BOOKING_TEMPLATE_INPUT}
          />
        </S.Section>
        <StyledSavedTemplatesEdit onClick={() => setEditMode(!editMode)}>
          {!editMode && <span>{t('bookings.templates.edit')}</span>}
          {editMode && <span>{t('actions.cancel')}</span>}
        </StyledSavedTemplatesEdit>
        <Placeholder
          ready={status === STATUS_FULFILLED}
          customPlaceholder={<BookingFiltersSavedSkeleton count={8} />}
        >
          <StyledBookingCreateList>{items}</StyledBookingCreateList>
        </Placeholder>
      </Sidebar.Content>
    </Sidebar>
  )
}

export default BookingTemplates
