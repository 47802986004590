import { object, string, number, InferType, mixed, array, boolean } from 'yup'

import { mapInfoSchema } from 'views/trusted_routes/types/map_info'

enum AccuracyEnum {
  VESSEL = 'vessel',
  AVERAGE = 'average',
}

export enum TransportEnum {
  VESSEL = 'vessel',
  FEEDER_BARGE = 'feeder_barge',
  TRUCK_RAIL = 'truck_rail',
  FEEDER = 'feeder',
  TRUCK = 'truck',
  RAIL = 'rail',
  INTERMODAL = 'intermodal',
  MULTIMODAL = 'multimodal',
  BARGE = 'barge',
  UNKNOWN = 'unknown',
}

export enum RateType {
  ONLINE = 'Online',
  CONTRACT = 'Contract',
}

export enum Category {
  SPOT = 'Spot',
  NAC = 'NAC',
  FAK = 'FAK',
  COMMODITY = 'Commodity',
}

export type Notation = 'A' | 'B' | 'C' | 'D' | 'E'

const rateSchema = object({
  departure: string().required(),
  arrival: string().required(),
  carrier: string().required(),
  type: mixed().oneOf(Object.values(RateType)),
  category: mixed().oneOf(Object.values(Category)),
  reference: string().nullable().defined(),
  nac: string().nullable().defined(),
  commodity: string().nullable().defined(),
  validityBegin: string().nullable().defined(),
  validityEnd: string().nullable().defined(),
  validitySpot: string().nullable().defined(),
  rate20Dry: number().nullable().defined(),
  rate40Dry: number().nullable().defined(),
  rate40Hc: number().nullable().defined(),
  currency: string().nullable().defined(),
  service: string().nullable().defined(),
})

const emissionSchema = object({
  co2E: number().required(),
  accuracy: mixed().oneOf(Object.values(AccuracyEnum)).required(),
})

const reliabilitySchema = object({
  estimatedTransitTime: number().required(),
  notation: string<Notation>().nullable().defined(),
})

const stepSchema = object({
  date: string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Must be a date')
    .required(),
  port: object({
    name: string().required(),
    locode: string().required(),
    country: string().nullable().defined(),
  }),
})

const alertSchema = object({
  messageEn: string().required(),
  messageFr: string().required(),
})

const legSchema = object({
  type: mixed<TransportEnum>().oneOf(Object.values(TransportEnum)).required(),
  vesselName: string().nullable().defined(),
  departure: stepSchema.required(),
  arrival: stepSchema.required(),
  service: string().nullable().defined(),
  voyage: string().nullable().defined(),
  vesselImo: number().nullable().defined(),
  alerts: array().of(alertSchema).nullable().defined(),
})

export const trustedRouteSchema = object({
  token: string().required(),
  scac: string().required(),
  legs: array().of(legSchema).required(),
  cutOffDate: string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Must be a date')
    .nullable()
    .defined(),
  transitTime: number().required(),
  emission: emissionSchema.nullable().defined(),
  reliability: reliabilitySchema.nullable().defined(),
  rates: array().of(rateSchema).nullable().defined(),
  visible: boolean().required().default(false),
  bestRate20Dry: rateSchema.nullable().default(null),
  bestRate40HC: rateSchema.nullable().default(null),
  bestRate40Dry: rateSchema.nullable().default(null),
  mapInfo: mapInfoSchema.nullable().default(null),
})

export type Step = InferType<typeof stepSchema>
export type Leg = InferType<typeof legSchema>
export type Rate = InferType<typeof rateSchema>
export type TrustedRoute = InferType<typeof trustedRouteSchema>
