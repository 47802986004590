import React, { useEffect, useRef } from 'react'

import { Controller, useForm } from 'react-hook-form'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTimeout from 'services/hooks/use_timeout'
import FiltersSidebar from 'components/filters_sidebar'
import Input from 'components/input'
import MultiSelect from 'components/multiselect'
import CheckboxPicto from 'components/checkbox_picto'
import S from 'views/atlas/hubs/components/filters/style'
import { HubTypeFilter, saveHubsActiveFilters } from 'views/atlas/slices/hub'
import fetchStates from 'views/atlas/slices/state'
import fetchCountries from 'views/atlas/slices/country'
import { HUB_TYPE_ICONS } from 'views/atlas/helpers'
import {
  AirportType,
  CityType,
  DepotType,
  PortType,
  RailStationType,
  SymbolicLocationType,
} from 'views/atlas/types/hub'

export interface HubsForm {
  countries: { value: string; label: string }[]
  states: { value: string; label: string }[]
  search: string
  hubTypeFilters: Record<HubTypeFilter, boolean>
}

const HubFilters: React.FC = () => {
  const dispatch = useAppDispatch()
  const { onTimeout } = useTimeout({ timeout: 500 })

  const { handleSubmit, register, control, reset, watch } = useForm<HubsForm>({
    defaultValues: {
      countries: [],
      states: [],
      search: '',
      hubTypeFilters: {
        port: false,
        airport: false,
        railStation: false,
        city: false,
        depot: false,
      },
    },
  })

  const onSubmit = (data: HubsForm): void => {
    dispatch(
      saveHubsActiveFilters({
        search: data.search,
        countries: data.countries.map((country) => country.value),
        states: data.states.map((state) => state.value),
        hubTypeFilters: data.hubTypeFilters,
      })
    )
  }

  const values = watch()
  const firstUpdate = useRef<boolean>(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    onTimeout(handleSubmit(onSubmit))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values)])

  const apply = () => {
    handleSubmit(onSubmit)()
  }

  const clear = () => {
    reset()
    apply()
  }

  return (
    <FiltersSidebar onClear={clear} onApply={apply}>
      <FiltersSidebar.Section title='Search' defaultOpened>
        <Input
          type='text'
          placeholder='name, locode, iata_code or icao_code'
          {...register('search')}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section title='HubType'>
        <S.HubTypesContainer>
          <Controller
            name='hubTypeFilters.port'
            control={control}
            render={({ field }) => (
              <CheckboxPicto
                icon={HUB_TYPE_ICONS[PortType]}
                id={field.name}
                name={field.name}
                text='Port'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
              />
            )}
          />
          <Controller
            name='hubTypeFilters.airport'
            control={control}
            render={({ field }) => (
              <CheckboxPicto
                icon={HUB_TYPE_ICONS[AirportType]}
                id={field.name}
                name={field.name}
                text='Airport'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
              />
            )}
          />
          <Controller
            name='hubTypeFilters.railStation'
            control={control}
            render={({ field }) => (
              <CheckboxPicto
                icon={HUB_TYPE_ICONS[RailStationType]}
                id={field.name}
                name={field.name}
                text='Rail Station'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
              />
            )}
          />
          <Controller
            name='hubTypeFilters.city'
            control={control}
            render={({ field }) => (
              <CheckboxPicto
                icon={HUB_TYPE_ICONS[CityType]}
                id={field.name}
                name={field.name}
                text='City'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
              />
            )}
          />
          <Controller
            name='hubTypeFilters.depot'
            control={control}
            render={({ field }) => (
              <CheckboxPicto
                icon={HUB_TYPE_ICONS[DepotType]}
                id={field.name}
                name={field.name}
                text='Depot'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
              />
            )}
          />
          <Controller
            name='hubTypeFilters.symbolicLocation'
            control={control}
            render={({ field }) => (
              <CheckboxPicto
                icon={HUB_TYPE_ICONS[SymbolicLocationType]}
                id={field.name}
                name={field.name}
                text='Symbolic Location'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
              />
            )}
          />
        </S.HubTypesContainer>
      </FiltersSidebar.Section>

      <FiltersSidebar.Section title='Country'>
        <Controller
          name='countries'
          control={control}
          render={({ field }) => (
            <MultiSelect
              name={field.name}
              isSearchable
              async
              value={field.value}
              onChange={({ value }) => field.onChange(value)}
              fetch={({ value }) => fetchCountries({ value })}
              fetchOnFocus={() => fetchCountries({ value: null })}
              fetchedOptionsFormat={(options) =>
                options.map(({ name, token }: { name: string; token: string }) => ({
                  value: token,
                  label: name,
                }))
              }
            />
          )}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section title='State'>
        <Controller
          name='states'
          control={control}
          render={({ field }) => (
            <MultiSelect
              name={field.name}
              isSearchable
              async
              value={field.value}
              onChange={({ value }) => field.onChange(value)}
              fetch={({ value }) => fetchStates({ value })}
              fetchOnFocus={() => fetchStates({ value: null })}
              fetchedOptionsFormat={(options) =>
                options.map(({ name, token }: { name: string; token: string }) => ({
                  value: token,
                  label: name,
                }))
              }
            />
          )}
        />
      </FiltersSidebar.Section>
    </FiltersSidebar>
  )
}

export default HubFilters
