import { TrustedRoute } from 'views/trusted_routes/types/trusted_route'
import { compactArray } from 'services/helpers/values'

export const allLocodes = (trustedRoute: TrustedRoute): string[] => [
  trustedRoute.legs[0].departure.port.locode,
  ...trustedRoute.legs.map((leg) => leg.arrival.port.locode),
]

export const allVesselImos = (trustedRoute: TrustedRoute): number[] =>
  compactArray(trustedRoute.legs.map((leg) => leg.vesselImo))
