/* eslint-disable no-underscore-dangle */
import i18next from 'i18next'
import Moment from 'moment/min/moment-with-locales'

import { isNull, isPresent } from 'services/helpers/values'

class DateHelper {
  constructor(date) {
    this._originalDate = date
    this._date = new Moment(date).utc()
    this._date.locale(i18next.languages?.[0] || 'en')
  }

  diff = (dateTo, measure = 'day') => this._date.diff(dateTo, measure)

  toSlash = () => this.format('DD/MM/yyyy')

  toDateOnly = () => this.format('yyyy-MM-DD')

  toSlashAndHour = () => `${this.format('DD/MM/YY')} - ${this.format('H:mm')}`

  toLocale = ({ hours } = { hours: false }) =>
    !hours || !this.hasHours() ? this.format('ll') : this.format('ll - HH:mm')

  formattedOriginalDate = () => new Moment(this._originalDate).format('ll')

  format = (format) => this._date.format(format)

  toCreation = () => {
    const now = new Moment().utc()
    let formattedDate = this._date.format('MM/DD/YYYY')
    if (now.isSame(this._date, 'day')) {
      formattedDate = this._date.format('H:mm')
    } else if (now.isSame(this._date, 'year')) {
      formattedDate = this._date.format('MMM DD')
    }
    return formattedDate
  }

  isBetweenDates = (dateA, dateB) => {
    if (isPresent(dateB)) {
      return (
        this._date.isSameOrAfter(new Date(dateA).toDateString()) &&
        this._date.isSameOrBefore(new Date(dateB).toDateString())
      )
    }
    return this._date.isSameOrAfter(new Date(dateA).toDateString())
  }

  isBeforeOrEqual = (date) => this._date.isSameOrBefore(date)

  isAfterOrEqual = (date) => this._date.isSameOrAfter(date)

  isBefore = (date) => this._date.isBefore(date)

  isAfter = (date) => this._date.isAfter(date)

  isEqual = (date) => {
    if (isNull(date)) {
      return false
    }
    const dateToCompare = new Date(date).toDateString()
    return (
      this._date.isSame(dateToCompare, 'day') &&
      this._date.isSame(dateToCompare, 'year') &&
      this._date.isSame(dateToCompare, 'month')
    )
  }

  toDate = () => this._date.toDate()

  // TODO: REFACTOR:
  // This is a hack so that TypeScript infers return type string
  toISOString = () => `${this._date.toISOString()}`

  rangeToday = () => [
    this._date.startOf('day').toISOString(),
    this._date.endOf('day').toISOString(),
  ]

  rangeThisWeek = () => [
    this._date.startOf('week').toISOString(),
    this._date.endOf('week').toISOString(),
  ]

  rangeThisMonth = () => [
    this._date.startOf('month').toISOString(),
    this._date.endOf('month').toISOString(),
  ]

  hasHours = () => this._date.hours() !== 0 || this._date.minutes() !== 0

  addDays = (days) => {
    const newDate = new Date(this._date)
    newDate.setDate(newDate.getDate() + days)
    return newDate
  }

  addYears = (years) => this._date.add(years, 'years').toDate()
}

export default DateHelper
