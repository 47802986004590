import {
  VEHICLE_TYPE_VESSEL,
  VEHICLE_TYPE_PLANE,
  VEHICLE_TYPE_TRAIN,
  VEHICLE_TYPE_TRUCK,
  VEHICLE_TYPE_BARGE,
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_COMPANY,
  LOCATION_TYPE_PORT,
  LOCATION_TYPE_SYMBOLIC,
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_RAIL,
  TRANSPORT_TYPE_ROAD,
  TRANSPORT_TYPE_SEA,
  TRANSPORT_TYPE_PARCEL,
  FACILITY_TYPE_INLAND,
  FACILITY_TYPE_AIRPORT,
  FACILITY_TYPE_DEPOT,
  FACILITY_TYPE_TRAIN_STATION,
  FACILITY_TYPE_PORT,
  FACILITY_TYPE_SYMBOLIC,
  TRANSPORT_TYPE_ICONS,
  TRUCK_ICON,
  VESSEL_ICON,
  PLANE_ICON,
  TRAIN_ICON,
  BARGE_ICON,
} from 'constants/shipments'

const iconsMapping = (name, context) => {
  // default mapping
  let mapping = {}

  // Transportation mapping
  if (context === 'transportation') {
    mapping = {
      ...mapping,
      ...TRANSPORT_TYPE_ICONS,
      [VEHICLE_TYPE_TRUCK]: TRUCK_ICON,
      [VEHICLE_TYPE_VESSEL]: VESSEL_ICON,
      [VEHICLE_TYPE_PLANE]: PLANE_ICON,
      [VEHICLE_TYPE_TRAIN]: TRAIN_ICON,
      [VEHICLE_TYPE_BARGE]: BARGE_ICON,
    }
  }

  // Timeline mapping
  if (context === 'timeline') {
    mapping = {
      ...mapping,
      [FACILITY_TYPE_SYMBOLIC]: 'factory',
      [FACILITY_TYPE_INLAND]: 'factory',
      [FACILITY_TYPE_AIRPORT]: 'airport',
      [FACILITY_TYPE_DEPOT]: 'container',
      [FACILITY_TYPE_TRAIN_STATION]: 'rail_road',
      [FACILITY_TYPE_PORT]: 'anchor',
      [TRANSPORT_TYPE_SEA]: 'anchor',
      [TRANSPORT_TYPE_ROAD]: 'factory',
      [TRANSPORT_TYPE_AIR]: 'airport',
      [TRANSPORT_TYPE_RAIL]: 'rail_road',
      [TRANSPORT_TYPE_PARCEL]: 'parcel',
    }
  }

  // Address mapping
  if (context === 'address') {
    mapping = {
      ...mapping,
      [LOCATION_TYPE_PORT]: 'anchor',
      [LOCATION_TYPE_AIRPORT]: 'airport',
      [LOCATION_TYPE_COMPANY]: 'factory',
      [LOCATION_TYPE_SYMBOLIC]: 'factory',
    }
  }

  return mapping[name] || name
}

export default iconsMapping
