import React, { useState, FC, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import Button from 'components/button'
import Page from 'components/page'
import useModal from 'components/modal/hooks/use_modal'

import { StyledBookingCreate } from 'views/booking/components/create/style'
import BookingForm from 'views/booking/components/form'
import BookingTemplates from 'views/booking/components/create/templates'
import useBookingForm from 'views/booking/components/form/hooks/use_booking_form'
import ValidateCreateModal from 'views/booking/components/create/validate_create_modal'
import { StyledFooterButton } from 'views/booking/components/show/style'
import BookingNewContext from 'views/booking/contexts/booking_new_context'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import { BookingFromTrustedRoutes } from 'views/booking/slices/types'
import { formatDefaultValues } from 'views/booking/components/create/helpers'

import {
  StyledFormFooter,
  StyledPageBookingDetailBoxShadow,
  StyledPageBookingDetailButtonBack,
  StyledPageBookingDetailEditButtonBack,
  StyledPageBookingDetailInner,
  StyledPageBookingDetailsHeader,
} from 'views/booking/style'

import { FormProvider } from 'services/providers/form_provider'
import S from 'components/page/style'

import { TEST_ID_BOOKING_SEND_REQUEST_BUTTON } from 'tests/e2e/test_ids'

const BookingCreate: FC = () => (
  <FormProvider>
    <WrappedBookingCreate />
  </FormProvider>
)

const WrappedBookingCreate = () => {
  const { state } = useLocation<BookingFromTrustedRoutes | undefined>()
  const [uploadedFiles, setUploadedFiles] = useState([])
  const history = useHistory()
  const { t } = useTranslation()
  const { organization } = useCurrentUser()
  const { filters, isValid, queryParams } = useBookingForm({
    booking: formatDefaultValues(state?.booking, organization),
  })

  const contextValue = useMemo(() => ({ uploadedFiles, setUploadedFiles }), [uploadedFiles])

  const { setOpen } = useModal('bookingValidateCreate')

  return (
    <Page sidebar={<BookingTemplates />}>
      <ValidateCreateModal queryParams={queryParams} uploadedFiles={uploadedFiles} />
      <BookingNewContext.Provider value={contextValue}>
        <StyledBookingCreate>
          <StyledPageBookingDetailsHeader>
            <StyledPageBookingDetailEditButtonBack
              as={StyledPageBookingDetailButtonBack}
              text={t('actions.backToList')}
              variant='transparent'
              icon='arrow_left'
              onClick={() => history.push('/bookings')}
            />
            <S.PageTitle>{t('bookings.newBooking')}</S.PageTitle>
          </StyledPageBookingDetailsHeader>

          <StyledPageBookingDetailBoxShadow>
            <StyledPageBookingDetailInner>
              <BookingForm filters={filters} isCreate routingWarning={state?.routingWarning} />
            </StyledPageBookingDetailInner>
            <StyledFormFooter>
              <StyledFooterButton
                as={Button}
                text={t('bookings.actions.sendRequest')}
                onClick={() => setOpen(true)}
                rounded
                icon='send'
                variant='highlight'
                disabled={!isValid}
                testId={TEST_ID_BOOKING_SEND_REQUEST_BUTTON}
              />
            </StyledFormFooter>
          </StyledPageBookingDetailBoxShadow>
        </StyledBookingCreate>
      </BookingNewContext.Provider>
    </Page>
  )
}

export default BookingCreate
