import React, { SyntheticEvent, FC } from 'react'

import Form from 'components/form'
import References from 'views/booking/components/form/references'
import Parties from 'views/booking/components/form/parties'
import Incoterms from 'views/booking/components/form/incoterms'
import KeyContacts from 'views/booking/components/form/key_contacts'
import Comments from 'views/booking/components/form/comments'
import RateConfirmation from 'views/booking/components/form/rate_confirmation'
import Routing from 'views/booking/components/form/routing'
import TransportDetails from 'views/booking/components/form/transport_details'
import Merchandise from 'views/booking/components/form/merchandise'
import Miscellaneous from 'views/booking/components/form/miscellaneous'

import { WITH_FRONT_BOOKING_TRANSPORT_DETAILS } from 'constants/organization_features'
import { StyledPageBookingDetailTabs, StyledForm } from 'views/booking/components/form/style'
import BookingFormDocuments from 'views/booking/components/form/documents'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'

import type { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

const BookingForm: FC<BookingFormProps> = ({ filters, isCreate, routingWarning }) => {
  const { hasRateConfirmationFeature } = useBookingRole()
  const { features } = useOrganizationCan()

  return (
    <StyledPageBookingDetailTabs>
      <StyledForm as={Form} onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
        <References filters={filters} />
        <Parties filters={filters} />
        <Incoterms filters={filters} />
        <Routing filters={filters} routingWarning={routingWarning} />
        {features(WITH_FRONT_BOOKING_TRANSPORT_DETAILS) && (
          <TransportDetails filters={filters} isCreate={isCreate} />
        )}
        <Miscellaneous filters={filters} isCreate={isCreate} />
        <Merchandise filters={filters} />
        {hasRateConfirmationFeature && <RateConfirmation filters={filters} />}
        <KeyContacts filters={filters} />
        {isCreate && (
          <Form.Group title='Documents'>
            <BookingFormDocuments />
          </Form.Group>
        )}
        <Comments filters={filters} />
      </StyledForm>
    </StyledPageBookingDetailTabs>
  )
}

export default BookingForm
