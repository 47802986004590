import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'

import Page from 'components/page'
import HubFilters from 'views/atlas/hubs/components/filters'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import usePagination from 'services/api/hooks/use_pagination'
import {
  fetchHubs,
  removeAllHubs,
  selectHubs,
  selectHubsActiveFilters,
  selectHubsStatus,
  selectHubsTotalCount,
} from 'views/atlas/slices/hub'
import useStatus from 'services/api/hooks/use_status'
import useTriggerOnViewport from 'services/hooks/use_trigger_on_viewport'
import S from 'views/atlas/style'
import StyledPage from 'components/page/style'
import Table from 'components/table'
import Button from 'components/button'
import useModal from 'components/modal/hooks/use_modal'
import HubForm from 'views/atlas/hubs/components/hub_form'
import { Hub } from 'views/atlas/types/hub'
import NotifMessage from 'components/notif_message'

const HubIndex: React.FC = () => {
  const dispatch = useAppDispatch()
  const { page, next, reset } = usePagination()
  const { t } = useTranslation()
  const hubs = useSelector(selectHubs)
  const totalCount = useSelector(selectHubsTotalCount)
  const activeFilters = useSelector(selectHubsActiveFilters)
  const fetchingStatus = useStatus(useSelector(selectHubsStatus))
  const { opened, setOpen } = useModal('hubForm')
  const theme = useTheme()
  const [editHub, setEditHub] = useState<null | Hub>(null)

  useEffect(() => {
    resetAllHubs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(activeFilters)])

  const resetAllHubs = () => {
    dispatch(removeAllHubs())
    triggerFetchHubs(1)
    reset()
  }

  const triggerFetchHubs = (p: number) => {
    dispatch(
      fetchHubs({
        search: activeFilters.search,
        countries: activeFilters.countries,
        hubTypeFilters: activeFilters.hubTypeFilters,
        states: activeFilters.states,
        page: p,
      })
    )
  }

  const nextPage = () => {
    triggerFetchHubs(page + 1)
    next()
  }

  const ref = useTriggerOnViewport<HTMLDivElement>(nextPage)

  return (
    <Page sidebar={<HubFilters />}>
      <S.Header>
        <StyledPage.PageTitle>Hubs</StyledPage.PageTitle>
        <S.Text>{t('atlas.hubCounter', { count: totalCount })}</S.Text>
        <Button
          text={t('atlas.actions.newHub')}
          icon='plus_outline'
          variant='highlight'
          onClick={() => {
            setEditHub(null)
            setOpen(true)
          }}
          type='button'
        />
      </S.Header>
      <div>
        {hubs.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell />
              <Table.HeadCell>name</Table.HeadCell>
              <Table.HeadCell>locode</Table.HeadCell>
              <Table.HeadCell>locodeAliases</Table.HeadCell>
              <Table.HeadCell>iataCode</Table.HeadCell>
              <Table.HeadCell>icaoCode</Table.HeadCell>
              <Table.HeadCell>state</Table.HeadCell>
              <Table.HeadCell>country</Table.HeadCell>
              <Table.HeadCell />
            </Table.Head>
            <Table.Body>
              {hubs.map((hub, index) => (
                <Table.Row odd={index % 2 === 1} alignedTop key={hub.token}>
                  <Table.Cell>{hub.type}</Table.Cell>
                  <Table.Cell>{hub.name}</Table.Cell>
                  <Table.Cell>{'locode' in hub && hub.locode ? hub.locode : '_'}</Table.Cell>
                  <Table.Cell>
                    {'locodeAliases' in hub && hub.locodeAliases.length > 0
                      ? hub.locodeAliases?.join(', ')
                      : '_'}
                  </Table.Cell>
                  <Table.Cell>{'iataCode' in hub ? hub.iataCode : '_'}</Table.Cell>
                  <Table.Cell>{'icaoCode' in hub ? hub.icaoCode : '_'}</Table.Cell>
                  <Table.Cell>{hub.state?.name || '_'}</Table.Cell>
                  <Table.Cell>{'country' in hub ? hub.country?.name : '_'}</Table.Cell>
                  <Table.Cell>
                    <Button
                      type='button'
                      icon='pencil'
                      variant='verySmallIcon'
                      onClick={() => {
                        setEditHub(hub)
                        setOpen(true)
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        {fetchingStatus.ready && hubs.length < totalCount && <div ref={ref} />}
        {fetchingStatus.pending && (
          <S.Loader>
            <ClipLoader loading size={20} aria-label='Loading Spinner' color={theme.primary} />
          </S.Loader>
        )}
        {fetchingStatus.fulfilled && hubs.length === 0 && (
          <NotifMessage
            type='info'
            title={t('atlas.noResults.title')}
            text={t('atlas.noResults.text')}
          />
        )}
      </div>
      {opened && <HubForm callbackAfterSave={resetAllHubs} hub={editHub} />}
    </Page>
  )
}

export default HubIndex
